import TagManager from "@sooro-io/react-gtm-module";
import React from "react";
import CookieConsent from "react-cookie-consent";
import { BrowserRouter, useLocation } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Routes from "./config/Routes";
import { MenuContextProvider } from "./contexts/menu-context";
import { ModelContextProvider } from "./contexts/model-context";

const App = () => {
  const [tagmanagerActive, setTagmanagerActive] = React.useState(false);

  const CookieBanner = () => {
    const history = useLocation();

    if (
      history.pathname !== "/bejelentkezes" &&
      history.pathname !== "/f9a3c2dd20d43eda5d3e522ba2b79dfc" &&
      history.pathname !== "/f9a3c2dd20d43eda5d3e522ba2b79dfc.html"
    ) {
      return (
        <CookieConsent
          acceptOnScroll={true}
          acceptOnScrollPercentage={4}
          onAccept={(acceptedByScrolling) => {
            if (
              history.pathname !== "/bejelentkezes" &&
              history.pathname !== "/f9a3c2dd20d43eda5d3e522ba2b79dfc" &&
              history.pathname !== "/f9a3c2dd20d43eda5d3e522ba2b79dfc.html"
            ) {
              if (!tagmanagerActive) {
                TagManager.initialize({ gtmId: "GTM-5Q4TKR7T" });
                setTagmanagerActive(true);
              }
              document.getElementById("sutiNemElfogadva").id = "sutiElfogadva";
            }
          }}
          buttonText="Elfogadom"
          cookieName="sutiBeleegyezes"
          style={{ background: "#000000" }}
          buttonStyle={{
            color: "white",
            background: "#000000",
            border: "1px solid white",
            borderRadius: "5px",
            textTransform: "none",
          }}
          expires={120}>
          Az oldal használatával hozzájárulsz a cookie-k alkalmazásához.
        </CookieConsent>
      );
    } else {
      return <> </>;
    }
  };

  return (
    <BrowserRouter>
      <MenuContextProvider>
        <ModelContextProvider>
          <Header />
          <Routes />
          <Footer />
          <CookieBanner />
        </ModelContextProvider>
      </MenuContextProvider>
    </BrowserRouter>
  );
};

export default App;
