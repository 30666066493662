import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import config from "../../contexts/config";
import FloatingChatButton from "../floatingchat/FloatingChat";

import "../solar/solar-model.scss";

const PromocioModel = () => {
  const chatButton = useRef();
  const [blogData, setBlogData] = useState({});
  const { id } = useParams();

  useEffect(() => {
    fetch(`${config.API_URL_ONRENDER}/blog/` + id)
      .then((res) => res.json())
      .then((data) => {
        setBlogData(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [id]);

  return (
    <>
      <div className="solar">
        <div className="solar__hero fade-bottom">
          <img
            src={require(`../../assets/solar/promocio.jpg`)}
            className="full-imagee"
            alt="hero"></img>
          <div
            className="solar__hero__info"
            style={{ color: "#fff", zIndex: 1, marginTop: "40dvh" }}>
            <motion.h1
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}>
              Vidéki Otthonfelújítási Program
            </motion.h1>
            <motion.h2
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}>
              2025. január 1-jétől indul a Vidéki Otthonfelújítási Program
            </motion.h2>
            <motion.h2
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}>
              50%-os vissza nem térítendő támogatás
            </motion.h2>
          </div>
        </div>

        <div
          className="solar__section__specs"
          style={{
            marginBottom: "150px",
            backgroundColor: "white",
            color: "black",
          }}>
          <div
            className="solar__section__specs__div"
            style={{ flexWrap: "wrap" }}>
            <div className="solar__section__specs__div__main">
              <motion.h1
                style={{ textAlign: "center", marginBottom: "12px" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                Vidéki Otthonfelújítási Program
              </motion.h1>
              <motion.h2
                style={{ textAlign: "center", marginBottom: "12px" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                2025. január 1-jétől indul a Vidéki Otthonfelújítási Program
              </motion.h2>
              <motion.h2
                style={{ textAlign: "center" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                50%-os vissza nem térítendő támogatás
              </motion.h2>
              <motion.p
                style={{ marginTop: "24px" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                A Green Island Invest Zrt. teljeskörű pályázati ügyintézést
                nyújt, beleértve az elszámoláshoz szükséges alapdokumentáció
                elkészítését és hitelügyintéző biztosítását is.
              </motion.p>
              <motion.p
                style={{ marginTop: "24px", marginBottom: "24px" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                Nálunk a támogatási kérelem sikeres elbírálása garantált, minden
                lépést megfelelően, a legnagyobb precizitással végzünk el. Vedd
                fel velünk a kapcsolatot, és konzultálj szakértőinkkel az
                általad tervezett felújítási munkálatról.
              </motion.p>
              <motion.h2
                style={{ textAlign: "center", marginBottom: "12px" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                Segítségképpen összegyűjtöttük számodra a legfontosabb
                információkat!
              </motion.h2>
              <motion.h3
                style={{ marginBottom: "24px" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                Általános információk:
              </motion.h3>
              <motion.ul
                style={{ marginBottom: "12px" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                <li style={{ marginBottom: "12px", listStyleType: "disc" }}>
                  A felújítási támogatás összege{" "}
                  <strong>maximum 3 millió forint</strong>
                  (50%-50% arányban anyagköltség és munkadíj).
                </li>
                <li style={{ marginBottom: "12px", listStyleType: "disc" }}>
                  A számlákkal igazolt{" "}
                  <strong>felújítási költségek legfeljebb 50%-a.</strong>
                </li>
                <li style={{ marginBottom: "12px", listStyleType: "disc" }}>
                  A támogatás <strong>utófinanszírozott</strong>, vagyis a
                  költségeket előre meg kell előlegezni.
                </li>
                <li style={{ marginBottom: "12px", listStyleType: "disc" }}>
                  A kiadások megelőlegezésére elérhető lesz a bankoknál egy{" "}
                  <strong>
                    legfeljebb 6 millió forintos kedvezményes hitelkonstrukció,
                  </strong>{" "}
                  amelyre az állam háromszázalékpontos kamattámogatást nyújt.
                </li>
                <li style={{ marginBottom: "12px", listStyleType: "disc" }}>
                  Ha az igénylő igénybe vette a 2021-2022-ben élő
                  otthonfelújítási támogatást, akkor a{" "}
                  <strong>
                    3 millió forint és a korábbi támogatás különbözetére lehet
                    jogosult.
                  </strong>
                </li>
                <li style={{ marginBottom: "24px", listStyleType: "disc" }}>
                  Gyakorlatilag minden meglévő lakóingatlanhoz kapcsolódó
                  felújítási és építési munkálatra igényelhető a támogatás,
                  beleértve{" "}
                  <strong>
                    új napelemes rendszerek telepítését vagy már meglévő
                    napelemes rendszerek bővítését és/vagy cseréjét is.
                  </strong>
                </li>
              </motion.ul>
              <motion.h3
                style={{ marginBottom: "12px" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                Feltételek:
              </motion.h3>
              <motion.ul
                style={{ marginBottom: "12px" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                <li style={{ marginBottom: "12px", listStyleType: "disc" }}>
                  Nagykorú, cselekvőképes természetes személy (magyar
                  állampolgár) igényelheti a támogatást (azonos lakóhellyel
                  rendelkező{" "}
                  <strong>
                    házas- és élettársak csak együttes igénylőként
                  </strong>
                  ).
                </li>
                <li style={{ marginBottom: "12px", listStyleType: "disc" }}>
                  Legalább egy <strong>25 év alatti gyermeket</strong> (vér
                  szerinti/örökbefogadott/kiskorú rokon, akinek a kirendelt
                  gyámja az igénylő) vagy a 25. életévét már betöltötte, de
                  megváltozott munkaképességű személyt nevel saját
                  háztartásában.
                </li>
                <li style={{ marginBottom: "12px", listStyleType: "disc" }}>
                  A támogatás a még meg nem született, de{" "}
                  <strong>12. hetet betöltött magzatra</strong> is igényelhető.
                </li>
                <li style={{ marginBottom: "12px", listStyleType: "disc" }}>
                  A felújítandó épület{" "}
                  <strong>5000 főnél kisebb népességű településen</strong>
                  található.
                </li>
                <li style={{ marginBottom: "12px", listStyleType: "disc" }}>
                  Valamennyien a{" "}
                  <strong>
                    felújítással érintett ingatlanban lakóhellyel rendelkeznek,
                  </strong>{" "}
                  amelyben az igénylő vagy igénylők és gyermekeik együttes{" "}
                  <strong>tulajdoni hányada eléri legalább az 50%-ot.</strong>
                </li>
                <li style={{ marginBottom: "12px", listStyleType: "disc" }}>
                  Az igénylő (házastársak, élettársak esetén legalább az egyik
                  fél) 
                  <strong>
                    legalább 1 éve – egybefüggően legfeljebb 30 nap
                    megszakítással– folyamatos TB jogviszonnyal 
                  </strong>
                  rendelkezik Magyarországon vagy külföldön, vagy felsőfokú
                  tanulmányt folytatott, vagy gyermekgondozási díjban részesült,
                  vagy kiegészítő tevékenységet folytató személynek minősül.
                </li>
                <li style={{ marginBottom: "12px", listStyleType: "disc" }}>
                  Az igénylő, együttes igénylés esetén házastársa vagy élettársa{" "}
                  <strong>
                    nem rendelkezhet 5000 forint feletti köztartozással.
                  </strong>
                </li>
                <li style={{ marginBottom: "12px", listStyleType: "disc" }}>
                  <strong>
                    Az igénylőknek és gyermekeiknek vállalniuk kell, hogy 5 évig
                    életvitelszerűen a támogatással érintett ingatlanban laknak.
                  </strong>
                </li>
                <li style={{ marginBottom: "24px", listStyleType: "disc" }}>
                  Ha nem azonos lakóhelyen élnek a szülők, akkor{" "}
                  <strong>
                    az jogosult a támogatásra, akinél legalább egy közös gyermek
                    lakóhelye van.
                  </strong>
                </li>
              </motion.ul>
              <motion.h3
                style={{ marginBottom: "12px" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                Támogatás igénylésének menete:
              </motion.h3>
              <motion.ul
                style={{ marginBottom: "12px" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                <li style={{ marginBottom: "12px", listStyleType: "disc" }}>
                  A támogatás a <strong>2025. január 1-jét</strong> követően
                  megkötött <strong>vállalkozási szerződés alapján</strong>
                   megkezdett, a lakáson végzett felújítás befejezését és a
                  számlák kifizetését követően igényelhető:
                  <ul
                    style={{
                      marginBottom: "12px",
                      marginTop: "12px",
                      paddingLeft: "36px",
                    }}>
                    <li
                      style={{
                        marginBottom: "12px",
                        listStyleType: "disclosure-closed",
                      }}>
                      Elektronikus úton ügyfélkapus bejelentkezést követően.
                    </li>
                    <li
                      style={{
                        marginBottom: "12px",
                        listStyleType: "disclosure-closed",
                      }}>
                      Postai úton történő benyújtás (cím: Magyar Államkincstár,
                      1938 Budapest).
                    </li>
                    <li
                      style={{
                        marginBottom: "24px",
                        listStyleType: "disclosure-closed",
                      }}>
                      Személyesen a Kincstár területi szerveinél nyújtható be.
                    </li>
                  </ul>
                </li>
              </motion.ul>
              <motion.p
                style={{ marginBottom: "24px" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                <strong>
                  Fontos! A támogatás iránti kérelmet az utolsó számla
                  kifizetését követő 60 napon belül, de legkésőbb 2026. június
                  30-ig lehet benyújtani. EGYSZERI ALKALOMMAL LEHETSÉGES A
                  KÉRELEM BENYÚJTÁSA,
                </strong>{" "}
                amelyhez csatolni kell az összes felújítási munkáról szóló
                szerződést és számlát, számlaösszesítő táblázattal együtt.
              </motion.p>
              <motion.h3
                style={{ marginBottom: "12px" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                Támogatás elbírálásának menete:
              </motion.h3>
              <motion.p
                style={{ marginBottom: "12px" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                <strong>90 napos határidővel, </strong>amit legfeljebb egyszer
                hosszabbíthatnak meg 45 nappal.
              </motion.p>
              <motion.p
                style={{ marginBottom: "24px" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                A támogatás igénybevételének jogszerűségét a folyósítást
                követően 1 évig, a bentlakási kötelezettséget{" "}
                <strong>5 évig ellenőrizheti akár a helyszínen is</strong> a
                kincstár a kormányhivatal bevonásával.
              </motion.p>
              <motion.h3
                style={{ marginBottom: "12px" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                Általános információk a kedvezményes kölcsönről:
              </motion.h3>
              <motion.p
                style={{ marginBottom: "24px" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                <strong>Legfeljebb 6 millió forintot lehet felvenni</strong>{" "}
                kedvezményes kölcsönként ezzel megelőlegezve a beruházási
                költségeket. Ehhez legfeljebb{" "}
                <strong>3 százalékpontos kamattámogatást</strong> ad az állam a
                teljes futamidőre, ami legfeljebb 10 év lehet.
              </motion.p>
              <motion.h3
                style={{ marginBottom: "12px" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                Néhány hasznos információ:
              </motion.h3>
              <motion.p
                style={{ marginBottom: "12px" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                A visszaigénylés során csak az érintett ingatlanban állandó
                lakcímmel rendelkező személyek relevánsak (gyerekek esetében
                pedig csak a 25 év alattiak). 18 év feletti gyerek(ek) esetében
                kötelezően benyújtandó a gyerek által aláírt adatkezelési
                hozzájárulás.
              </motion.p>
              <motion.p
                style={{ marginBottom: "12px" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                Amennyiben az igénylő a felújítással érintett ingatlanban
                házastással/élettárssal él együtt, a kérelem együttesen
                nyújtható be, azaz az igénylő és igénylőtársa is szerepel a
                kérelemben. A társ nyilatkozatban járul hozzá, hogy a másik fél
                igénylőként érvényesítse a támogatást.
              </motion.p>
              <motion.p
                style={{ marginBottom: "12px" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                Elvált szülők/különélő házastársak/élettársak esetén csak akkor
                kell lemondó nyilatkozatot aláíratni a másik szülővel, ha az ő
                címén is van állandó lakcímre bejelentett közös gyerek, aki
                jogosulttá teszi őt is a támogatás igénybevételére. Ebben az
                esetben a másik szülő lemondó nyilatkozatának hiányában az
                igénylő csak 25%-nyi támogatásra jogosult.
              </motion.p>
              <motion.p
                style={{ marginBottom: "12px" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                Napelemes rendszer telepítését vagy cseréjét igazoló számla
                esetén – a tetősíkba integrált, napenergia-hasznosítást
                biztosító tetőhéjazat kivételével – az abban feltüntetett
                anyagköltség és kapcsolódó vállalkozói díj összesítve nem
                haladhatja meg a bruttó 450 000 Ft/kWp összeget.
              </motion.p>
              <motion.h3
                style={{ marginBottom: "12px" }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                A konkrét pályázati kiírás az alábbi linkre kattintva érhető el
                (település listával együtt):
              </motion.h3>
              <motion.a
                style={{ marginBottom: "24px", textDecoration: "underline" }}
                href="https://www.allamkincstar.gov.hu/csaladok-tamogatasa/Csalad_gyermek/videki-otthonfelujitasi-program/a-videki-otthonfelujitasi-program-kereteben-nyujthato-otthonfelujitasi-tamogatas"
                target="_blank"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                KATTINTSON IDE A PÁLYÁZATI KIÍRÁS MEGTEKINTÉSÉHEZ
              </motion.a>
              <motion.img
                style={{
                  marginTop: "24px",
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}
                src={require(`../../assets/discount/osszefoglalo.png`)}
                alt="hero"></motion.img>
            </div>
          </div>
        </div>

        <div className="solar__footer" id="kapcsolat">
          <div className="solar__footer__container">
            <h2>Green Island Invest Zrt.</h2>
            <div style={{ fontSize: "0.8rem" }}>
              <p>2074 Perbál Ipari Park 17</p>
              <p>info@greenislandinvest.hu</p>
              <p>+36 1 300 9240</p>
              <p>+36 70 / 315-7070</p>
              <p>+36 70 / 309-7070</p>
            </div>
            <div className="solar__footer__container__btns">
              <button
                className="btn-secondary-inverse"
                onClick={() => chatButton.current.openChat()}>
                Visszahívást kérek
              </button>
            </div>
          </div>
        </div>
      </div>
      <FloatingChatButton ref={chatButton} />
    </>
  );
};

export default PromocioModel;
