import EmailJS from "@emailjs/browser";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import TagManager from "@sooro-io/react-gtm-module";
import { useRef, useState } from "react";
import { getCookieConsentValue } from "react-cookie-consent";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../contexts/config";
import "./discountModal.scss";

const style = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
};

const DiscountModal = ({
  isOpen,
  onClose,
  title,
  description,
  showButton,
  vallalati,
  options,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [iranyitoszam, setiranyitoszam] = useState("");
  const [hasChild, setHasChild] = useState(false);
  const [hasParticipated, setHasParticipated] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false);
  const recaptchaRef = useRef(null);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleiranyitoszamChange = (e) => {
    setiranyitoszam(e.target.value);
  };

  const handleHasChildChange = (e) => {
    setHasChild(e.target.checked);
  };

  const handleHasParticipatedChange = (e) => {
    setHasParticipated(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    recaptchaRef.current.execute();
  };

  const handleRecaptchaChange = (token) => {
    if (!token) {
      setError(true);
      return;
    }
    let evaluli = hasChild
      ? "Van 25 éven aluli gyermeke a háztartásban."
      : "Nincsen 25 éven aluli gyermeke a háztartásban.";
    let resztvett = hasParticipated
      ? "Részt vett korábbi Otthonfelújítási Programban."
      : "Nem vett részt korábbi Otthonfelújítási Programban.";
    EmailJS.send("service_x9pofce", "template_4o454ss", {
      name,
      email,
      phone,
      iranyitoszam,
      evaluli,
      resztvett,
    })
      .then(() => {
        if (getCookieConsentValue("sutiBeleegyezes") === "true") {
          TagManager.initialize({ gtmId: "GTM-5Q4TKR7T" });
          TagManager.dataLayer({
            dataLayer: {
              event: "generate_lead",
              form_type: "otthonfelujitas",
              name: name,
              email: email,
              phone: phone,
              iranyitoszam: iranyitoszam,
            },
          });
        }
        fetch(`${config.API_URL_ONRENDER}/minicrm/addUser`, {
          method: "PUT",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Name: name,
            Email: email,
            Phone: phone,
            Type: "Business",
            recaptchaToken: token,
          }),
        })
          .then((response) => {
            setLoading(false);
            if (!response.ok) {
              setError(true);
              return response.json().then((error) => {
                throw new Error(error.message || "Unknown error");
              });
            }
            setName("");
            setEmail("");
            setPhone("");
            setiranyitoszam("");
            setHasChild(false);
            setHasParticipated(false);
            setSuccess(true);
            setError(false);

            return response.json();
          })
          .catch((error) => {
            setError(true);
            setLoading(false);
            console.error("Error:", error);
          });
      })
      .catch(() => {
        setSuccess(false);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  let loadingButton;

  if (isLoading) {
    loadingButton = (
      <div className="load-wrapp">
        <div className="load-2">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
    );
  } else {
    if (isSuccess) {
      loadingButton = <span>Sikeres beküldés!</span>;
    } else if (isError) {
      loadingButton = <span>Hiba történt!</span>;
    } else {
      loadingButton = <span>Visszahívás</span>;
    }
  }

  let color;

  if (isLoading) {
    color = "black";
  } else {
    if (isSuccess) {
      color = "#008000";
    } else if (isError) {
      color = "#C70039";
    } else {
      color = "black";
    }
  }

  return (
    <div
      className="discount-modal-backdrop"
      style={{ opacity: isOpen ? 1 : 0, pointerEvents: isOpen ? "" : "none" }}>
      <div className="discount-modal-content">
        <button className="close-button" onClick={onClose}>
          ×
        </button>
        <div
          className={
            vallalati ? "image-container-vallalati" : "image-container"
          }></div>
        <div className="form-container">
          <div className="text-container">
            <h1>{title}</h1>
            <p>{description}</p>
            {showButton ? (
              <button
                style={{ float: "left", backgroundColor: color }}
                type="button"
                className="button-gomb"
                onClick={() => (window.location.href = "/otthonfelujitas")}>
                További részletek
              </button>
            ) : (
              <> </>
            )}

            {options ? (
              <ul className="options-list">
                {options.map((option, index) => (
                  <li key={index}>- {option}</li>
                ))}
              </ul>
            ) : (
              <></>
            )}
          </div>
          <form
            onSubmit={handleSubmit}
            className={
              options && options.length !== 0
                ? "vallalati-modal"
                : "lakossagi-modal"
            }>
            <div className="input-container">
              <TextField
                label="Teljes Név"
                value={name}
                sx={style}
                size="small"
                required={true}
                onChange={handleNameChange}
              />
              <TextField
                sx={style}
                size="small"
                label="E-mail cím"
                value={email}
                required={true}
                onChange={handleEmailChange}
              />
              <TextField
                label="Telefonszám"
                value={phone}
                sx={style}
                size="small"
                required={true}
                onChange={handlePhoneChange}
              />
              {options && options.length !== 0 ? (
                <></>
              ) : (
                <TextField
                  label="Irányítószám"
                  value={iranyitoszam}
                  sx={style}
                  size="small"
                  required={true}
                  onChange={handleiranyitoszamChange}
                />
              )}
            </div>
            {options && options.length !== 0 ? (
              <></>
            ) : (
              <div className="checkbox-container">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hasChild}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          color: "#000000",
                        },
                      }}
                      onChange={handleHasChildChange}
                    />
                  }
                  label="Van 25 év alatti gyermeked, aki egy háztartásban él veled?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hasParticipated}
                      iconstyle={{ fill: "black" }}
                      onChange={handleHasParticipatedChange}
                    />
                  }
                  label="Vettél már részt korábbi Otthonfelújítási Programban?"
                />
              </div>
            )}
            <button
              type="submit"
              className="discount-submit-button"
              style={{ backgroundColor: color }}
              disabled={isLoading}>
              {loadingButton}
            </button>
          </form>
          <ReCAPTCHA
            size="invisible"
            sitekey="6LdvmMEqAAAAANhHg48CnjWGHzSULz69qbkrOvws"
            ref={recaptchaRef}
            onChange={handleRecaptchaChange}
            badge="bottomleft"
            theme="light"
          />
        </div>
      </div>
    </div>
  );
};

export default DiscountModal;
