import React, { createContext, useEffect, useState } from "react";
import authService from "../api/authService";
import employees from "../components/bejelentkezes/0ae127cd7614241e3a8527b0fa1a1900.json";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(authService.getCurrentUser());

  useEffect(() => {
    const user = authService.getCurrentUser();
    setCurrentUser(user);
  }, []);

  const checkTokenValidity = () => {
    const tokenExpiration = localStorage.getItem("tokenExpiration");
    if (tokenExpiration && new Date().getTime() > tokenExpiration) {
      logout();
    }
  };

  useEffect(() => {
    checkTokenValidity();
    const interval = setInterval(checkTokenValidity, 60000); // Check every minute

    return () => clearInterval(interval);
  }, []);

  const login = async (username, password) => {
    try {
      const response = await authService.login(username, password);
      console.log("Login response:", response);

      if (!response.token) {
        throw new Error("Invalid login response");
      }

      const token = response.token;
      const expirationTime = new Date().getTime() + 86400 * 1000; // expiresIn is in seconds
      localStorage.setItem("token", token);
      localStorage.setItem("tokenExpiration", expirationTime);

      // Find the user in the employees JSON file
      const user = employees[username];
      if (user) {
        localStorage.setItem("nev", user.nev);
        localStorage.setItem("telefonszam", user.telefonszam);
        localStorage.setItem("felhasznalonev", username);
      }

      setCurrentUser(authService.getCurrentUser());
    } catch (error) {
      console.error("Failed to login", error);
      throw error; // Re-throw the error to be handled by the caller
    }
  };

  const logout = () => {
    authService.logout();
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiration");
    localStorage.removeItem("nev");
    localStorage.removeItem("telefonszam");
    localStorage.removeItem("felhasznalonev");
    setCurrentUser(null);
  };

  return (
    <AuthContext.Provider value={{ currentUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
