import axios from "axios";
import { jwtDecode } from "jwt-decode";
import config from "../contexts/config";

const API_URL = config.API_URL_ONRENDER;

const login = async (username, password) => {
  const response = await axios.post(`${API_URL}/login`, { username, password });
  if (response.data.token) {
    localStorage.setItem("token", response.data.token);
  }
  return response.data;
};

const logout = () => {
  localStorage.removeItem("token");
};

const getCurrentUser = () => {
  const token = localStorage.getItem("token");
  if (token) {
    return jwtDecode(token);
  }
  return null;
};

const authService = {
  login,
  logout,
  getCurrentUser,
};

export default authService;
