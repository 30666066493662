import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  InputAdornment,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { motion } from "framer-motion";
import React, { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import config from "../../contexts/config";

import FloatingChatButton from "../floatingchat/FloatingChat";

import "./otthonfelujitaskitoltes.scss";

const OtthonFelujitasKitoltes = () => {
  const chatButton = useRef();
  const fieldConfig = {
    Divider1: { label: "Igénylő adatai", type: "dividerMain" },
    Divider2: { label: "Igénylő neve", type: "divider" },
    Titulus: { label: "Titulus", type: "text", required: false },
    Csaladinev: { label: "Családi név", type: "text", required: true },
    Utonev: { label: "Utónév", type: "text", required: true },
    Masodikutonev: {
      label: "Második útónév",
      type: "text",
      required: false,
    },
    Divider3: { label: "Születési név", type: "divider" },
    Szuletesicsaladinev: {
      label: "Családi név",
      type: "text",
      required: true,
    },
    Szuletesiutonev: {
      label: "Utónév",
      type: "text",
      required: true,
    },
    Szuletesimasodikutonev: {
      label: "Második utónév",
      type: "text",
      required: false,
    },
    Divider4: { label: "Anyja születési neve", type: "divider" },
    Anyjacsaladinev: {
      label: "Családi név",
      type: "text",
      required: true,
    },
    Anyjautonev: { label: "Utónév", type: "text", required: true },
    Anyjamasodikutonev: {
      label: "Második utónév",
      type: "text",
      required: false,
    },
    Divider5: { label: "Születési hely, idő", type: "divider" },
    Szuletesihely: {
      label: "Születési hely",
      type: "text",
      required: true,
    },
    Szuletesiido: {
      label: "Születési idő",
      type: "date",
      required: true,
    },
    Divider6: {
      label: "Állampolgárság, adóazonosító, TAJ szám",
      type: "divider",
    },
    Allampolgarsag: {
      label: "Igénylő mely ország állampolgára?",
      type: "text",
      required: true,
    },
    Adoazonositojel2: {
      label: "Igénylő adóazonosító jele",
      type: "text",
      required: true,
    },
    Tajszam: { label: "Az igénylő TAJ száma", type: "text", required: true },
    Divider7: { label: "Lakóhelye (állandó lakcíme)", type: "divider" },
    Varmegye: { label: "Vármegye", type: "text", required: true },
    Iranyitoszam: { label: "Irányítószám", type: "text", required: true },
    Telepules: { label: "Település", type: "text", required: true },
    Kozteruletneve: { label: "Közterület neve", type: "text", required: true },
    Kozteruletjellege: {
      label: "Közterület jellege",
      type: "text",
      required: true,
    },
    Hazszam: { label: "Épület/Házszám", type: "text", required: true },
    Lepcsohazi: { label: "Lépcsőház", type: "text", required: false },
    Emelet: { label: "Emelet", type: "text", required: false },
    Ajto: { label: "Ajtó", type: "text", required: false },
    Nyilvantartottkoztartozas: {
      label:
        "Az állami adóhatóságnál van nyilvántartott, 5.000 Ft-ot meghaladó köztartozása?",
      type: "dropdown",
      options: ["van", "nincs"],
      required: true,
    },
    Egyuttesigenylok: {
      label:
        "Együttes igénylők, mert a házastársa/élettársa lakóhelye megegyezik az Ön lakóhelyével?",
      type: "dropdown",
      options: ["igen", "nem"],
      required: true,
    },
    Szuloklakhelyeeltero: {
      label:
        "A szülők lakóhelye eltérő és mindkét szülő lakóhelye külön-külön megegyezik legalább egy-egy gyermekük lakóhelyével?",
      type: "dropdown",
      options: ["igen", "nem"],
      required: true,
    },
    Szulolemondonyilatkozata: {
      label:
        "Amennyiben az előző kérdésre igen a válasz, rendelkezik a másik szülő lemondó nyilatkozatával?",
      type: "dropdown",
      options: ["igen", "nem"],
      required: true,
    },
    Divider8: {
      label: "Együttes igénylés esetén töltse ki a házastárs/élettárs adatait",
      type: "dividerMain",
    },
    Divider9: { label: "Házastárs/élettárs neve", type: "divider" },
    Elettarstitulus: {
      label: "Titulus",
      type: "text",
      required: false,
    },
    Elettarscsaladinev: {
      label: "Családi név",
      type: "text",
      required: false,
    },
    Elettarsutonev: { label: "Utónév", type: "text", required: false },
    Elettarsmasodikutonev: {
      label: "Második utónév",
      type: "text",
      required: false,
    },
    Divider10: { label: "Házastárs/élettárs születési neve", type: "divider" },
    Elettarsszuletesicsaladinev: {
      label: "Családi név",
      type: "text",
      required: false,
    },
    Elettarsszuletesiutonev: {
      label: "Utónév",
      type: "text",
      required: false,
    },
    Elettarsszuletesimasodikutonev: {
      label: "Második utónév",
      type: "text",
      required: false,
    },
    Divider11: {
      label: "Házastárs/élettárs anyjaának születési neve",
      type: "divider",
    },
    Elettarsanyjacsaladinev: {
      label: "Családi név",
      type: "text",
      required: false,
    },
    Elettarsanyjautonev: {
      label: "Utónév",
      type: "text",
      required: false,
    },
    Elettarsanyjamasodikutonev: {
      label: "Második utónév",
      type: "text",
      required: false,
    },
    Divider12: {
      label: "Házastárs/élettárs születési hely, idő",
      type: "divider",
    },
    Elettarsszuletesihely: {
      label: "Születési hely",
      type: "text",
      required: false,
    },
    Elettarsszuletesiido: {
      label: "Születési idő",
      type: "date",
      required: false,
    },
    Elettarsallampolgarsag: {
      label: "Házastárs/élettárs mely ország állampolgára?",
      type: "text",
      required: false,
    },
    Elettarsadoazonositojel: {
      label: "Házastárs/élettárs adóazonosító jel",
      type: "text",
      required: false,
    },
    Elettarstajszam: {
      label: "Házastárs/élettárs TAJ száma",
      type: "text",
      required: false,
    },
    Divider13: {
      label: "Házastárs/élettárs lakóhelye (állandó lakcíme)",
      type: "divider",
    },
    Elettarsiranyitoszam: {
      label: "Irányítószám",
      type: "text",
      required: false,
    },
    Elettarstelepules: {
      label: "Település",
      type: "text",
      required: false,
    },
    Elettarskozteruletneve: {
      label: "Közterület neve",
      type: "text",
      required: false,
    },
    Elettarskozteruletjellege: {
      label: "Közterület jellege",
      type: "text",
      required: false,
    },
    Elettarshazszam: {
      label: "Épület/Házszám",
      type: "text",
      required: false,
    },
    Elettarslepcsohaz: {
      label: "Lépcsőház",
      type: "text",
      required: false,
    },
    Elettarsemelet: { label: "Emelet", type: "text", required: false },
    Elettarsajto: { label: "Ajtó", type: "text", required: false },
    Elettarsnyilvantartottkoztartozas: {
      label:
        "Az állami adóhatóságnál van nyilvántartott, 5.000 Ft-ot meghaladó köztartozása?",
      type: "dropdown",
      options: ["van", "nincs"],
      required: true,
    },
    Divider14: { label: "Gyermekekre vonatkozó adatok", type: "dividerMain" },
    Magzatfigyelembevetel: {
      label:
        "Kéri-e figyelembe venni a kérelmező, illetve élettársa/házastársa várandósságát, ha a magzat 12 hétnél idősebb?",
      type: "dropdown",
      options: ["igen", "nem"],
      required: true,
    },
    Magzatdokumentumnyilatkozat: {
      label:
        "Amennyiben az előző válasz igen, nyilatkozom, hogy az élveszületést követően a születési anyakönyvi kivonatot, vagy a halvaszületést vagy a magzat elhalását követően az erre vonatkozó okiratot a Kincstár részére 60 napon belül benyújtom.",
      type: "dropdown",
      options: ["igen", "nem"],
      required: false,
    },
    Divider15: { label: "A gyermek családi és utóneve", type: "divider" },
    Gyermektitulus: { label: "Titulus", type: "text", required: true },
    Gyermekcsaladinev: {
      label: "Családi név",
      type: "text",
      required: true,
    },
    Gyermekutonev: { label: "Utónév", type: "text", required: true },
    Gyermekmasodikutonev: {
      label: "Második utónév",
      type: "text",
      required: false,
    },
    Divider16: { label: "A gyermek születési helye és ideje", type: "divider" },
    Gyermekszuletesihely: {
      label: "Születési hely",
      type: "text",
      required: true,
    },
    Gyermekszuletesiido: {
      label: "Születési idő",
      type: "date",
      required: true,
    },
    Gyermekcsaladijogiallasahoz: {
      label: "Gyermek családi jogi állása",
      type: "text",
      required: true,
    },
    Divider17: { label: "A gyermek anyjának adatai", type: "divider" },
    Gyermekanyjacsaladinev: {
      label: "Családinév",
      type: "text",
      required: true,
    },
    Gyermekanyjautonev: {
      label: "Utónév",
      type: "text",
      required: true,
    },
    Gyermekanyjamasodikutonev: {
      label: "Második utónév",
      type: "text",
      required: false,
    },
    Divider18: {
      label: "A gyermek lakóhelye (állandó lakcíme)",
      type: "divider",
    },
    Gyermekmiranyitoszam: {
      label: "Irányítószám",
      type: "text",
      required: true,
    },
    Gyermektelepules: {
      label: "Település",
      type: "text",
      required: true,
    },
    Gyermekkozteruletneve: {
      label: "Közterület neve",
      type: "text",
      required: true,
    },
    Gyermekkozteruletjellege: {
      label: "Közterület jellege",
      type: "text",
      required: true,
    },
    Gyermekhazszam: { label: "Épület/Házszám", type: "text", required: true },
    Gyermeklepcsohazi: {
      label: "Lépcsőház",
      type: "text",
      required: false,
    },
    Gyermekemelet: { label: "Emelet", type: "text", required: false },
    Gyermekajto: { label: "Ajtó", type: "text", required: false },
    Gyermekmegvaltozottmunkakepessegu: {
      label:
        "A 25. életévét betöltött gyermek esetében a gyermek megváltozott munkaképességű?",
      type: "dropdown",
      options: ["igen", "nem"],
      required: false,
    },
    Gyermekszuleinekhalalaeseten: {
      label: "A gyermek szüleinek halála esetén az igénylő a gyermek gyámja?",
      type: "dropdown",
      options: ["igen", "nem"],
      required: false,
    },
    Divider19: { label: "A gyermek apjának adatai", type: "divider" },
    Gyermekapjatitulus: {
      label: "Titulus",
      type: "text",
      required: false,
    },
    Gyermekapjacsaladinev: {
      label: "Családi név",
      type: "text",
      required: true,
    },
    Gyermekapjautonev: {
      label: "Utónév",
      type: "text",
      required: true,
    },
    Gyermekapjamasodikutonev: {
      label: "Második utónév",
      type: "text",
      required: false,
    },
    Divider20: { label: "A gyermek apjának születési adatai", type: "divider" },
    Gyermekapjaszuletesicsaladinev: {
      label: "Családi név",
      type: "text",
      required: true,
    },
    Gyermekapjaszuletesiutonev: {
      label: "Utónév",
      type: "text",
      required: true,
    },
    Gyermekapjaszuletesimasodikutonev: {
      label: "Második utónév",
      type: "text",
      required: false,
    },
    Divider21: { label: "Anyja születési neve", type: "divider" },
    Gyermekapjaanyjacsaladinev: {
      label: "Családi név",
      type: "text",
      required: true,
    },
    Gyermekapjaanyjautonev: {
      label: "Utónév",
      type: "text",
      required: true,
    },
    Gyermekapjaanyjamasodikutonev: {
      label: "Második utónév",
      type: "text",
      required: false,
    },
    Divider22: { label: "Születési helye és ideje", type: "divider" },
    Gyermekapjaszuletesihely: {
      label: "Születési hely",
      type: "text",
      required: true,
    },
    Gyermekapjaszuletesiido: {
      label: "Születési idő",
      type: "date",
      required: true,
    },
    Divider23: { label: "Lakóhelye (állandó lakcíme)", type: "divider" },
    Gyermekapjairanyitoszam: {
      label: "Irányítószám",
      type: "text",
      required: true,
    },
    Gyermekapjatelepules: {
      label: "Település",
      type: "text",
      required: true,
    },
    Gyermekapjakozteruletneve: {
      label: "Közterület neve",
      type: "text",
      required: true,
    },
    Gyermekapjakozteruletjellege: {
      label: "Közterület jellege",
      type: "text",
      required: true,
    },
    Gyermekapjahazszam: {
      label: "Épület/házszám",
      type: "text",
      required: true,
    },
    Gyermekapjalepcsohaz: {
      label: "Lépcsőház",
      type: "text",
      required: false,
    },
    Gyermekapjaemelet: {
      label: "Emelet",
      type: "text",
      required: false,
    },
    Gyermekapjaajto: {
      label: "Ajtó",
      type: "text",
      required: false,
    },
    Divider24: { label: "Az anya adatai", type: "divider" },
    Gyermekapjaanyatitulus: {
      label: "Titulus",
      type: "text",
      required: true,
    },
    Gyermekapjaanyacsaladinev: {
      label: "Családi név",
      type: "text",
      required: true,
    },
    Gyermekapjaanyautonev: {
      label: "Utónév",
      type: "text",
      required: true,
    },
    Gyermekapjaanyamasodikutonev: {
      label: "Második utónév",
      type: "text",
      required: false,
    },
    Divider25: { label: "Születési neve", type: "divider" },
    Gyermekapjaanyaszuletesicsaladinev: {
      label: "Családi név",
      type: "text",
      required: true,
    },
    Gyermekapjaanyaszuletesiutonev: {
      label: "Utónév",
      type: "text",
      required: true,
    },
    Gyermekapjaanyaszuletesimasodikutonev: {
      label: "Második utónév",
      type: "text",
      required: false,
    },
    Divider27: { label: "Anyja születési neve", type: "divider" },
    Gyermekapjaanyaanyjaszuletesicsaladinev: {
      label: "Családi név",
      type: "text",
      required: false,
    },
    Gyermekapjaanyaanyjaszuletesiutonev: {
      label: "Utónév",
      type: "text",
      required: false,
    },
    Gyermekapjaanyaanyjaszuletesimasodikutonev: {
      label: "Második utónév",
      type: "text",
      required: false,
    },
    Divider26: { label: "Születési helye és ideje", type: "divider" },
    Gyermekapjaanyaszuletesihely: {
      label: "Születési hely",
      type: "text",
      required: true,
    },
    Gyermekapjaanyaszuletesiido: {
      label: "Születési idő",
      type: "date",
      required: true,
    },
    Divider42: { label: "Lakóhelye (állandó lakcíme)", type: "divider" },
    Gyermekapjaanyairanyitoszam: {
      label: "Irányítószám",
      type: "text",
      required: true,
    },
    Gyermekapjaanyatelepules: {
      label: "Település",
      type: "text",
      required: true,
    },
    Gyermekapjaanyakozteruletneve: {
      label: "Közterület neve",
      type: "text",
      required: true,
    },
    Gyermekapjaanyakozteruletjellege: {
      label: "Közterület jellege",
      type: "text",
      required: true,
    },
    Gyermekapjaanyahazszam: {
      label: "Épület/Házszám",
      type: "text",
      required: true,
    },
    Gyermekapjallepcsohaz: {
      label: "Lépcsőház",
      type: "text",
      required: false,
    },
    Gyermekapjaemelet2: {
      label: "Emelet",
      type: "text",
      required: false,
    },
    Gyermekapjaajto2: {
      label: "Ajtó",
      type: "text",
      required: true,
    },
    Divider28: { label: "Szülőkre vonatkozó adatok", type: "dividerMain" },
    Divider29: { label: "A szülő családi és utóneve", type: "divider" },
    Szulotitulus: { label: "Titulus", type: "text", required: false },
    Szulocsaladinev: {
      label: "Családi név",
      type: "text",
      required: false,
    },
    Szuloutonev: { label: "Utónév", type: "text", required: false },
    Szulomasodikutonev: {
      label: "Második utónév",
      type: "text",
      required: false,
    },
    Divider30: { label: "Születési helye és ideje", type: "divider" },
    Szuloszuletesihely: {
      label: "Születési hely",
      type: "text",
      required: false,
    },
    Szuloszuletesiido: {
      label: "Szülő születési idő",
      type: "date",
      required: false,
    },
    Divider31: {
      label:
        "A felújításra, korszerűsítésre került ingatlanra vonatkozó adatok",
      type: "divider",
    },
    Divider32: { label: "Az ingatlan címe", type: "divider" },
    Ingatlaniranyitoszam: {
      label: "Irányítószám",
      type: "text",
      required: true,
    },
    Ingatlantelepules: {
      label: "Település",
      type: "text",
      required: true,
    },
    Ingatlankozteruletneve: {
      label: "Közterület neve",
      type: "text",
      required: true,
    },
    Ingatlankozteruletjellege: {
      label: "Közterület jellege",
      type: "text",
      required: true,
    },
    Ingatlanhazszam: {
      label: "Épület/Házszám",
      type: "text",
      required: true,
    },
    Ingatlanlepcsohaz: {
      label: "Lépcsőház",
      type: "text",
      required: false,
    },
    Ingatlanemelet: { label: "Emelet", type: "text", required: false },
    Ingatlanajto: { label: "Ajtó", type: "text", required: false },
    Ingatlanhelyrajziszam: {
      label: "Az ingatlan helyrajzi száma",
      type: "text",
      required: true,
    },
    Tobblakasbolallo: {
      label: "Több lakásból álló közös tulajdonú ingatlan?",
      type: "dropdown",
      options: ["igen", "nem"],
      required: false,
    },
    Ingatlanrendeltetesijellege: {
      label: "Ingatlan rendeltetési jellege",
      type: "dropdown",
      options: [
        "lakás",
        "lakóház",
        "tanya jogi jelleggel nyilvántartott ingatlan lakáscélú épületei",
        "birtokközpont jogi jelleggel nyilvántartott ingatlan lakáscélú épületei",
      ],
      required: true,
    },
    Ingatlanfoepitesianyaga: {
      label: "Az épület fő építési anyaga",
      type: "dropdown",
      options: [
        "vályog tégla",
        "tömör agyagtégla",
        "vázkerámia falazóelem tégla",
        "panel vagy beton",
        "könnyűszerkezetes falszerkezetes",
        "YTONG falazat",
      ],
      required: true,
    },
    Ingatlanepuletmerete: {
      label: "Az épület mérete",
      type: "text",
      addonText: "m2",
      required: true,
    },
    Ingatlanepitesenekeve: {
      label:
        "Kérjük, adja meg a felújítással érintett épület építésének évét az alábbi három lehetőségből választva!",
      type: "dropdown",
      options: [
        "1970 előtt épült",
        "1970-1990 közötti időszakban épült",
        "1990 után épült",
      ],
      required: true,
    },
    // Ingatlanepitesenekeve2: {
    //   label:
    //     "Kérjük, adja meg, hogy az otthonfelújítási támogatásból megvalósuló felújítások előtt mikor történt a legutóbbi, az energetikai állapotot is javító felújítás a lakóépületen! Amennyiben ilyen felújítás nem történt, azt a választ jelölje meg!",
    //   type: "dropdown",
    //   options: [
    //     "1970 előtt történt felújítás",
    //     "1970-1990 közötti időszakban történt felújítás",
    //     "1990 után történt felújítás",
    //     "nem történt felújítás",
    //   ],
    //   required: true,
    // },
    Ingatlanutolsofelujitas: {
      label:
        "Kérjük, adja meg, hogy az otthonfelújítási támogatásból megvalósuló felújítások előtt mikor történt a legutóbbi, az energetikai állapotot is javító felújítás a lakóépületen! Amennyiben ilyen felújítás nem történt, azt a választ jelölje meg!",
      type: "dropdown",
      options: [
        "1970 előtt történt felújítás",
        "1970-1990 közötti időszakban történt felújítás",
        "1990 után történt felújítás",
        "nem történt felújítás",
      ],
      required: true,
    },
    Ingatlanenergetikaitanusitvany: {
      label:
        "Kérjük, adja meg, hogy rendelkezik-e a felújított épületre vonatkozó energetikai tanúsítvánnyal!",
      type: "dropdown",
      options: ["igen", "nem"],
      required: true,
    },
    Ingatlanenergetikaitanusitvanykeszitesenekeve: {
      label:
        "Amennyiben a felújítással érintett épületre készült energetikai tanúsítvány, kérjük, adja meg az energetikai tanúsítvány készítésének évét!",
      type: "text",
      required: false,
    },
    Ingatlanenergetikaikategoria: {
      label:
        "Kérjük, adja meg az energetikai tanúsítvány alapján az épületre meghatározott energetikai kategóriát!",
      type: "dropdown",
      options: ["A", "B", "C", "D", "E", "F", "G"],
      required: false,
    },
    Igenylotulajdonihanyada: {
      label:
        "Az igénylő (házastárssal/élettárssal együtt) és/vagy gyermeke(i) és/vagy szülője tulajdoni hányada együttesen (%-ban kettő tizedes pontossággal megadva)",
      type: "text",
      addonText: "%",
      required: true,
    },
    Vettigenybehasznaltlakasvasarlasra: {
      label:
        "Használt lakás vásárlása és korszerűsítése vagy korszerűsítése és bővítése céljából?",
      type: "dropdown",
      options: ["igen", "nem"],
      required: true,
    },
    Vettigenybemeglevolakasfelujitasara: {
      label:
        "Meglévő lakás felújítása, korszerűsítése vagy korszerűsítése és bővítése céljából?",
      type: "dropdown",
      options: ["igen", "nem"],
      required: true,
    },
    Vettigenybeotthonfelujitasit: {
      label:
        "Korábban az „Otthonfelújítási Program Lakossági energiahatékonysági beruházások megvalósításához” (azonosító jel: RRF-REP-10.13.1-24) felhívás szerinti támogatást igénybe vette?",
      type: "dropdown",
      options: ["igen", "nem"],
      required: true,
    },
    Utolsoszamlakifizetese: {
      label: "Az utolsó számla kifizetésének napja",
      type: "text",
      required: true,
    },
    Voltotthonfelujitasikolcson: {
      label:
        "Az otthonfelújítás megvalósításához igénybe vette a Rendelet 7. alcíme szerinti otthonfelújítási kölcsönt?",
      type: "dropdown",
      options: ["igen", "nem"],
      required: true,
    },
    Voltotthonfelujitasitamogatas: {
      label:
        "Korábban az 518/2020. (XI. 25.) Korm. rendelet szerinti otthonfelújítási támogatást igénybe vette?",
      type: "dropdown",
      options: ["igen", "nem"],
      required: true,
    },
    Igenyloszamlaszam: {
      label: "Igénylő számlaszám",
      type: "text",
      required: true,
    },
    Igenyelttamogatasosszege: {
      label: "Igényelt támogatás összege",
      type: "text",
      required: true,
    },
    Divider52: { label: "Nyilatkozatok", type: "dividerMain" },
    Felujitasokatvegzo: {
      label:
        "Az állami adóhatóságnál van nyilvántartott, 5.000 Ft-ot meghaladó köztartozása?",
      type: "multiple",
      options: [
        "vállalkozó nem közeli hozzátartozóm és nem élettársam",
        "gazdálkodó szervezetnek sem én, sem közeli hozzátartozóm, élettársam nem tagja, nem munkavállalója és nem vezető tisztségviselője",
        "együttes igénylés esetén: mindkét feltétel mindkét igénylő esetében teljesül",
      ],
      required: true,
    },
    Biztositasigenyloviselt: {
      label: "Igénylő viselt neve",
      type: "text",
      required: true,
    },
    Biztositottkentszerepl: {
      label: "a Tbj. 6. §-a szerint biztosítottként szerepel",
      type: "dropdown",
      options: ["igen", "nem"],
      required: true,
    },
    Kiegeszitotevekenysegetfolytat: {
      label:
        "a Tbj. 4. § 11. pontja szerinti kiegészítő tevékenységet folytató személynek minősül (amit 30 napnál nem régebbi okirattal igazol)",
      type: "dropdown",
      options: ["igen", "nem"],
      required: true,
    },
    Nemzetkozimunka: {
      label:
        "keresőtevékenysége alapján valamely másik állam vagy nemzetközi szerződéssel létrehozott nemzetközi szervezet társadalombiztosítási rendszerének hatálya alá tartozott (amit az illetékes külföldi hatóság vagy a nemzetközi szervezet által kiállított igazolással igazol)",
      type: "dropdown",
      options: ["igen", "nem"],
      required: true,
    },
    Nappalioktatas: {
      label:
        "a nemzeti köznevelésről szóló 2011. évi CXC. törvény hatálya alá tartozó középfokú oktatási intézményben vagy a nemzeti felsőoktatásról szóló 2011. évi CCIV. törvény hatálya alá tartozó felsőoktatási intézményben nappali rendszerű oktatás keretében tanulmányokat folytatott/folytat.",
      type: "dropdown",
      options: ["igen", "nem"],
      required: true,
    },
    Nappalioktatasomazonosito: {
      label: "Oktatási intézmény OM azonosítója	",
      type: "text",
      required: false,
    },
    Nappalioktatasmegnevezese: {
      label: "Oktatási intézmény megnevezése, címe",
      type: "text",
      required: false,
    },
    Tanulmanyokmegkezdesenekideje: {
      label: "Tanulmányok megkezdésének időpontja	",
      type: "date",
      required: false,
    },
    Tanulmanyokbefejezesenekideje: {
      label: "Tanulmányok befejezésének várható időpontja",
      type: "date",
      required: false,
    },
    Kulfoldifelsooktatas: {
      label:
        "külföldi felsőoktatási intézmény nappali tagozatán folytatott olyan tanulmányokat, melyek a külföldi állam joga szerint kiállított olyan oklevélhez vezetnek, amely a külföldi bizonyítványok és oklevelek elismeréséről szóló 2001. évi C. törvény rendelkezései szerint egyenértékűnek ismerhető el a külföldi bizonyítványok és oklevelek elismeréséért felelős hatóság állásfoglalása alapján",
      type: "dropdown",
      options: ["igen", "nem"],
      required: true,
    },
    Gyermekgondozasidij: {
      label:
        "a kötelező egészségbiztosítás ellátásairól szóló 1997. évi LXXXIII. törvény 42/E. §-a alapján megállapított gyermekgondozási díjban részesült/részesül. („hallgatói GYED”)",
      type: "dropdown",
      options: ["igen", "nem"],
      required: true,
    },
    Gyermekekotthongondozasidijabanreszesul: {
      label:
        "végleges határozat alapján gyermekek otthongondozási díjában vagy ápolási díjban részesül",
      type: "dropdown",
      options: ["igen", "nem"],
      required: true,
    },
    Gyermekgyamkentnyugdijas: {
      label:
        "a gyermek(ek) szüleinek halála miatt kijelölt gyámként saját jogú nyugdíjas, vagy a reá irányadó nyugdíjkorhatárt betöltött özvegyi nyugdíjban részesülő személy",
      type: "dropdown",
      options: ["igen", "nem"],
      required: true,
    },
    Aziratokkezbesitesetkerem: {
      label: "A kérelmemmel érintett ügyintézés során az iratok ",
      type: "dropdown",
      options: ["postai kézbesítését kérem", "elektronikus kézbesítését kérem"],
      required: true,
    },
    Kelthely: {
      label: "Kelt hely",
      type: "text",
      required: true,
    },
    Keltev: {
      label: "Kelt év",
      type: "text",
      required: true,
    },
    Kelthonap: {
      label: "Kelt hónap",
      type: "text",
      required: true,
    },
    Keltnap: {
      label: "Kelt nap",
      type: "text",
      required: true,
    },
    Divider35: {
      label: "Lemondó nyilatkozat",
      type: "dividerMain",
      condition: "lemondo",
    },
    Divider36: {
      label: "Lemondo fél adatai",
      type: "divider",
      condition: "lemondo",
    },
    Lemondoszulocsaladiesutonev: {
      label: "Lemondó szülő családi és utónév",
      type: "text",
      required: true,
      condition: "lemondo",
    },
    Leondoszuloszuletesicsaladiesutonev: {
      label: "Lemondó szülő születési családi és utónév",
      type: "text",
      required: true,
      condition: "lemondo",
    },
    Lemondoanyjaszuletesicsaladiesutonev: {
      label: "Lemondó anyja születési családi és utónév",
      type: "text",
      required: true,
      condition: "lemondo",
    },
    Lemondoszuloszuletesihely: {
      label: "Lemondó szülő születési hely",
      type: "text",
      required: true,
      condition: "lemondo",
    },
    Lemondoszuloszuletesiido: {
      label: "Lemondó szülő születési idő",
      type: "date",
      required: true,
      condition: "lemondo",
    },
    Lemondolakcim: {
      label: "Lemondó lakcím",
      type: "text",
      required: true,
      condition: "lemondo",
    },
    Divider33: {
      label: "Együttes igénylés esetén a házastárs/élettárs nyilatkozata",
      type: "dividerMain",
      condition: "egyuttesigenyles",
    },
    Divider34: {
      label: "Házastárs/élettárs adatai",
      type: "divider",
      condition: "egyuttesigenyles",
    },
    Nyilatkozatelettarscsaladiesutonev: {
      label: "Nyilatkozat élettárs családi és utónév",
      type: "text",
      required: true,
      condition: "egyuttesigenyles",
    },
    Nyilatkozatelettarsszuletesicsaladiesutonev: {
      label: "Nyilatkozat élettárs születési családi és utónév",
      type: "text",
      required: true,
      condition: "egyuttesigenyles",
    },
    Nyilatkozatelettarsanyjaszuletesicsaladiesutonev: {
      label: "Nyilatkozat élettárs anyja születési családi és utónév",
      type: "text",
      required: true,
      condition: "egyuttesigenyles",
    },
    Nyilatkozatelettarsszuletesihely: {
      label: "Nyilatkozat élettárs születési hely",
      type: "text",
      required: true,
      condition: "egyuttesigenyles",
    },
    Nyilatkozatelettarsszuletesiido: {
      label: "Nyilatkozat élettárs születési idő",
      type: "date",
      required: true,
      condition: "egyuttesigenyles",
    },
    Nyilatkozatelettarslakcim: {
      label: "Nyilatkozat élettárs lakcím",
      type: "text",
      required: true,
      condition: "egyuttesigenyles",
    },
    Nyilatkozatelettarstajszam: {
      label: "Nyilatkozat élettárs TAJ szám",
      type: "text",
      required: true,
      condition: "egyuttesigenyles",
    },
    Divider41: {
      label:
        "Támogatással érintett lakásban tulajdonjoggal rendelkező szülő hozzájáruló nyilatkozata",
      type: "dividerMain",
      condition: "hozzajarulo",
    },
    Divider42: {
      label: "Szülő adatai",
      type: "divider",
      condition: "hozzajarulo",
    },

    Szulohozzajarulocsaladiesutonev: {
      label: "Szülő hozzájáruló családi és utónév",
      type: "text",
      required: true,
      condition: "hozzajarulo",
    },
    Szulohozzajaruloszuletesicsaladiesutonev: {
      label: "Szülő hozzájáruló születési családi és utónév",
      type: "text",
      required: true,
      condition: "hozzajarulo",
    },
    Szulohozzajaruloanyjaszuletesicsaladiesutonev: {
      label: "Szülő hozzájáruló anyja születési családi és utónév",
      type: "text",
      required: true,
      condition: "hozzajarulo",
    },
    Szulohozzajaruloszuletesiido: {
      label: "Szülő hozzájáruló születési idő",
      type: "date",
      required: true,
      condition: "hozzajarulo",
    },
    Szulohozzajaruloszuletesihely: {
      label: "Szülő hozzájáruló születési hely",
      type: "text",
      required: true,
      condition: "hozzajarulo",
    },
    Szulohozzajarulolakcim: {
      label: "Szülő hozzájáruló lakcím",
      type: "text",
      required: true,
      condition: "hozzajarulo",
    },
    Divider40: {
      label: "Nagykorú gyermek hozzájáruló nyilatkozata",
      type: "dividerMain",
      condition: "nagykoru",
    },
    Nagykorucsaladiesutonev: {
      label: "Nagykorú családi és utónév",
      type: "text",
      required: true,
      condition: "nagykoru",
    },
    Nagykoruszuletesicsaladiesutonev: {
      label: "Nagykorú születési családi és utónév",
      type: "text",
      required: true,
      condition: "nagykoru",
    },
    Nagykoruanyjaszuletesicsaladiesutonev: {
      label: "Nagykorú anyja születési családi és utónév",
      type: "text",
      required: true,
      condition: "nagykoru",
    },
    Nagykoruszuletesihely: {
      label: "Nagykorú születési hely",
      type: "text",
      required: true,
      condition: "nagykoru",
    },
    Nagykoruszuletesiido: {
      label: "Nagykorú születési idő",
      type: "date",
      required: true,
      condition: "nagykoru",
    },
    Nagykorulakcim: {
      label: "Nagykorú lakcím",
      type: "text",
      required: true,
      condition: "nagykoru",
    },
  };

  const [formData, setFormData] = useState(
    Object.keys(fieldConfig).reduce((acc, key) => {
      if (fieldConfig[key].type === "date") {
        acc[key] = new Date();
      } else if (fieldConfig[key].type === "multiple") {
        acc[key] = [];
      } else if (fieldConfig[key].defaultValue) {
        acc[key] = fieldConfig[key].defaultValue;
      } else {
        acc[key] = "";
      }
      return acc;
    }, {})
  );
  console.log(formData);

  const [password, setPassword] = useState("");
  const [isPasswordScreen, setIsPasswordScreen] = useState(true);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [szuksegesDokumentumokFilled, setSzuksegesDokumentumokFilled] =
    useState(false);
  const [szuksegesDokumentumok, setSzuksegesDokumentumok] = useState([]);
  const [dropdownValues, setDropdownValues] = useState({
    egyuttesigenyles: false,
    lemondo: false,
    nagykoru: false,
    hozzajarulo: false,
  });

  const handleSzuksegesDokumentumokNext = () => {
    const params = new URLSearchParams({ hash: lastPathElement, password });
    setNextIsLoading(true);
    fetch(
      `${config.API_URL_ONRENDER}/saveOtthonfelujitas?${params.toString()}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Szuksegesdokumentumok: szuksegesDokumentumok.join(","),
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setHighlightedFields([]);
          setNextIsLoading(false);
          setSzuksegesDokumentumokFilled(true);
          setTimeout(() => {
            const element = document.getElementById("kitoltes");
            element.style.scrollMarginTop = "100px";
            element.scrollIntoView({ behavior: "smooth" });
            element.style.scrollMarginTop = "0"; // Reset the scroll margin after scrolling
          }, 0);
        } else {
          setNextIsLoading(false);
          setNextError(true);
          setTimeout(() => {
            setNextError(false);
          }, 10000);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setShowPasswordError(false);
  };

  const location = useLocation();
  const pathnameArray = location.pathname.split("/");
  const lastPathElement = pathnameArray[pathnameArray.length - 1];

  const handlePasswordSubmit = () => {
    const params = new URLSearchParams({ hash: lastPathElement });
    setIsLoadingLogin(true);
    fetch(
      `${config.API_URL_ONRENDER}/otthonfelujitaspassword?${params.toString()}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          const updatedFormData = { ...formData };
          Object.keys(data).forEach((key) => {
            if (key === "Felujitasokatvegzo") {
              updatedFormData[key] = data[key]
                .split("|")
                .filter((e) => e !== "");
            } else if (key in updatedFormData) {
              updatedFormData[key] = data[key];
            }
          });
          setDropdownValues({
            ...dropdownValues,
            ...{
              egyuttesigenyles:
                data.Szuksegesdokumentumok.includes("egyuttesigenyles"),
            },
            ...{ lemondo: data.Szuksegesdokumentumok.includes("lemondo") },
            ...{ nagykoru: data.Szuksegesdokumentumok.includes("nagykoru") },
            ...{
              hozzajarulo: data.Szuksegesdokumentumok.includes("hozzajarulo"),
            },
          });
          setSzuksegesDokumentumok(data.Szuksegesdokumentumok.split(","));
          setFormData(updatedFormData);
          setIsPasswordScreen(false);
        } else {
          setShowPasswordError(true);
        }
        setIsLoadingLogin(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [showFillAllSteps, setShowFillAllSteps] = useState(false);

  const [currentStep, setCurrentStep] = useState(0);
  const [highlightedFields, setHighlightedFields] = useState([]);

  const fieldsPerPage = 10;
  const totalSteps = Math.ceil(Object.keys(formData).length / fieldsPerPage);

  const handleDropdownChange = (name, value) => {
    setDropdownValues((prevValues) => ({
      ...prevValues,
      [name]: JSON.parse(value),
    }));

    setSzuksegesDokumentumok((prevDocs) => {
      if (value === "true") {
        return [...prevDocs, name];
      } else {
        return prevDocs.filter((doc) => doc !== name);
      }
    });
  };

  const handleChange = (e) => {
    setShowFillAllSteps(false);
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setHighlightedFields((prev) => prev.filter((field) => field !== name));
  };

  const handleDateChange = (name, date) => {
    setFormData({ ...formData, [name]: date });
    setShowFillAllSteps(false);
    setHighlightedFields((prev) => prev.filter((field) => field !== name));
  };

  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#000",
      },
    },
    label: {
      overflow: "visible",
      whiteSpace: "normal",
    },
  });

  const [nextIsLoading, setNextIsLoading] = useState(false);
  const [nextError, setNextError] = useState(false);
  const [isFormCompleted, setIsFormCompleted] = useState(false);
  const handleNext = () => {
    const fields = [];
    let foundDividerMain = false;

    for (let i = currentStep; i < Object.keys(formData).length; i++) {
      const field = Object.keys(formData)[i];
      if (fieldConfig[field].type === "dividerMain" && fields.length > 0) {
        foundDividerMain = true;
        break;
      }
      fields.push(field);
    }

    const unfilledFields = fields.filter(
      (field) => fieldConfig[field].required && !formData[field]
    );

    if (unfilledFields.length === 0) {
      setNextIsLoading(true);
      const filteredFormData = Object.keys(formData).reduce((acc, key) => {
        if (
          fieldConfig[key].type !== "divider" &&
          fieldConfig[key].type !== "dividerMain"
        ) {
          if (fieldConfig[key].type === "multiple") {
            acc[key] = formData[key].join("|");
          } else {
            acc[key] = formData[key];
          }
        }
        return acc;
      }, {});

      const params = new URLSearchParams({ hash: lastPathElement, password });

      fetch(
        `${config.API_URL_ONRENDER}/saveOtthonfelujitas?${params.toString()}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(filteredFormData),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setNextIsLoading(false);

            if (currentStep + fields.length >= Object.keys(formData).length) {
              setIsFormCompleted(true);
            } else {
              setCurrentStep((prevStep) => prevStep + fields.length);
              setHighlightedFields([]);
            }
            setTimeout(() => {
              const element = document.getElementById("kitoltes");
              element.style.scrollMarginTop = "100px";
              element.scrollIntoView({ behavior: "smooth" });
              element.style.scrollMarginTop = "0"; // Reset the scroll margin after scrolling
            }, 0);
          } else {
            setNextIsLoading(false);
            setNextError(true);
            setTimeout(() => {
              setNextError(false);
            }, 10000);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setHighlightedFields(unfilledFields);
      setShowFillAllSteps(true);
    }
  };

  const [isLoadingLogin, setIsLoadingLogin] = useState(false);

  const handleBack = () => {
    const fields = [];
    let foundDividerMain = false;
    let includeDividerMain = false;

    for (let i = currentStep - 1; i >= 0; i--) {
      const field = Object.keys(formData)[i];
      if (fieldConfig[field].type === "dividerMain") {
        if (fields.length > 0) {
          foundDividerMain = true;
          includeDividerMain = true;
          break;
        } else {
          includeDividerMain = true;
        }
      }
      fields.unshift(field);
    }

    if (includeDividerMain) {
      fields.unshift(Object.keys(formData)[currentStep - fields.length - 1]);
    }

    setCurrentStep((prevStep) => Math.max(prevStep - fields.length, 0));

    setTimeout(() => {
      const element = document.getElementById("kitoltes");
      element.style.scrollMarginTop = "100px";
      element.scrollIntoView({ behavior: "smooth" });
      element.style.scrollMarginTop = "0"; // Reset the scroll margin after scrolling
    }, 0);
  };

  const filledFields = Object.values(formData).filter(
    (value) => value !== ""
  ).length;
  const progress = (filledFields / Object.keys(formData).length) * 100;

  const renderFields = () => {
    const fields = [];
    let foundDividerMain = false;

    for (let i = currentStep; i < Object.keys(formData).length; i++) {
      const field = Object.keys(formData)[i];
      const condition = fieldConfig[field].condition;

      if (fieldConfig[field].type === "dividerMain" && fields.length > 0) {
        foundDividerMain = true;
        break;
      }

      if (
        !condition ||
        szuksegesDokumentumok.includes(condition) ||
        (szuksegesDokumentumok.length > 0 && condition === "any")
      ) {
        fields.push(field);
      }
    }

    return fields.map((field, index) =>
      fieldConfig[field].type === "dividerMain" ? (
        <Grid item xs={12} lg={12} key={index} sx={{ mb: "-12px" }}>
          <h2>{fieldConfig[field].label}</h2>
        </Grid>
      ) : fieldConfig[field].type === "divider" ? (
        <Grid item xs={12} lg={12} key={index}>
          <h3>{fieldConfig[field].label}</h3>
        </Grid>
      ) : fieldConfig[field].type === "dropdown" ? (
        <React.Fragment key={index}>
          <Grid item xs={12} sm={12} md={10}>
            <InputLabel sx={{ whiteSpace: "normal", overflow: "visible" }}>
              {fieldConfig[field].label}
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={4} md={2} sx={{ mx: "auto" }}>
            <Select
              sx={{
                width: "100%",
              }}
              error={highlightedFields.includes(field)}
              name={field}
              value={formData[field]}
              onChange={handleChange}
              required={fieldConfig[field].required}>
              {fieldConfig[field].options.map((option, index) => (
                <MenuItem key={field + index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </React.Fragment>
      ) : fieldConfig[field].type === "multiple" ? (
        <React.Fragment key={index}>
          <Grid item xs={12} sm={12} md={12}>
            <InputLabel sx={{ whiteSpace: "normal", overflow: "visible" }}>
              {fieldConfig[field].label}
            </InputLabel>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ mx: "auto", display: "flex", justifyContent: "center" }}>
            <Select
              multiple
              error={highlightedFields.includes(field)}
              name={field}
              value={formData[field]}
              style={{ maxWidth: "100%" }}
              onChange={handleChange}
              required={fieldConfig[field].required}
              renderValue={(selected) => selected.join(", ")}>
              {fieldConfig[field].options.map((option, index) => (
                <MenuItem key={field + index} value={option}>
                  <Checkbox checked={formData[field].includes(option)} />
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </React.Fragment>
      ) : (
        <Grid item xs={12} lg={6} key={index}>
          {fieldConfig[field].type === "date" ? (
            <DatePicker
              name="szuletesiido"
              label="Születési idő"
              value={formData[field]}
              required={fieldConfig[field].required}
              onChange={(date) => handleDateChange(field, date)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          ) : (
            <TextField
              disabled={!!fieldConfig[field].defaultValue}
              name={field}
              error={highlightedFields.includes(field)}
              helperText={
                highlightedFields.includes(field) ? "Ez a mező kötelező!" : ""
              }
              label={fieldConfig[field].label}
              type={fieldConfig[field].type}
              required={fieldConfig[field].required}
              fullWidth
              InputProps={{
                endAdornment: fieldConfig[field].addonText ? (
                  <InputAdornment position="end">
                    {fieldConfig[field].InputAaddonText}
                  </InputAdornment>
                ) : (
                  <></>
                ),
              }}
              value={formData[field]}
              onChange={handleChange}
            />
          )}
        </Grid>
      )
    );
  };

  return (
    <>
      <div className="solar">
        <div className="solar__hero fade-bottom">
          <img
            src={require(`../../assets/discount/promocio.jpg`)}
            className="full-imagee"
            alt="hero"></img>
          <div
            className="solar__hero__info"
            style={{ color: "#fff", zIndex: 1, marginTop: "40dvh" }}>
            <motion.h1
              style={{ fontSize: "6rem" }}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}>
              Otthonfelújítás
            </motion.h1>
            <motion.h2
              style={{ fontSize: "1.5rem" }}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}>
              Dokumentumok kitöltése
            </motion.h2>
          </div>
        </div>

        <div
          className="solar__section__specs"
          id="kitoltes"
          style={{
            paddingBottom: "8rem",
            paddingTop: "4rem",
            backgroundColor: "white",
            color: "black",
          }}>
          <div
            className="solar__section__specs__div"
            style={{ flexWrap: "wrap" }}>
            <div
              className="solar__section__specs__div__main otthonfelujitas-form"
              style={{ maxWidth: "100%" }}>
              <ThemeProvider theme={defaultTheme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {isFormCompleted ? (
                    <Grid
                      container
                      spacing={3}
                      justifyContent="center"
                      alignItems="center"
                      style={{ marginBottom: "8rem", marginTop: "8rem" }}>
                      <Grid item xs={12} sm={12} md={12}>
                        <h2>
                          Köszönjük, hogy kitöltötte az Otthonfelújítási
                          kérelmeket!
                        </h2>
                        <p
                          style={{
                            marginTop: "16px",
                            marginRight: "auto",
                            marginLeft: "auto",
                            textAlign: "center",
                          }}>
                          Kollegáink hamarosan e-mailben keresni fogják!
                        </p>
                      </Grid>
                    </Grid>
                  ) : isPasswordScreen ? (
                    <Grid
                      container
                      spacing={3}
                      justifyContent="center"
                      alignItems="center"
                      style={{ marginBottom: "8rem", marginTop: "8rem" }}>
                      <Grid item xs={12} sm={12} md={12}>
                        <h2>
                          Kérjük adja meg jelszavát a dokumentumok kitöltéséhez!
                        </h2>
                        <p style={{ marginTop: "16px", marginBottom: "16px" }}>
                          Ahhoz, hogy tovább léphessen, kérjük adja meg a
                          jelszót amit e-mailben a kollegánktól kapott.
                        </p>
                        <TextField
                          label="Jelszó"
                          type="password"
                          fullWidth
                          value={password}
                          onChange={handlePasswordChange}
                        />
                        {showPasswordError ? (
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}>
                            <p style={{ color: "red", marginTop: "12px" }}>
                              <strong>
                                Hibás jelszó, kérjük próbálja újra!
                              </strong>
                            </p>
                          </Grid>
                        ) : (
                          <></>
                        )}
                        <Button
                          variant="contained"
                          color="primary"
                          className="button-fix"
                          disabled={password === "" || isLoadingLogin}
                          onClick={handlePasswordSubmit}
                          style={{ marginTop: "16px" }}>
                          {isLoadingLogin ? (
                            <CircularProgress size={24} />
                          ) : (
                            "Elkezdés"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  ) : szuksegesDokumentumokFilled ? (
                    <form>
                      <Grid container spacing={3}>
                        {renderFields()}
                        {showFillAllSteps ? (
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}>
                            <p style={{ color: "red" }}>
                              <strong>
                                Mielőtt tovább lépne, kérjük töltse ki az összes
                                kötelező mezőt!
                              </strong>
                            </p>
                          </Grid>
                        ) : (
                          <></>
                        )}
                        <Grid item xs={12}>
                          Kitöltés állapota:{" "}
                          <strong>{Math.round(progress)} %</strong>
                          <LinearProgress
                            variant="determinate"
                            color="primary"
                            value={progress}
                            sx={{ mt: 2 }}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}>
                          <Button
                            variant="contained"
                            color="primary"
                            className="button-fix"
                            onClick={handleBack}
                            disabled={currentStep === 0}
                            style={{ color: "black" }}>
                            Vissza
                          </Button>
                          <Button
                            variant="contained"
                            color={"primary"}
                            className="button-fix"
                            onClick={handleNext}
                            style={{
                              color: nextError ? "red !important" : "black",
                              outline: nextError ? "2px solid red" : "",
                            }}
                            disabled={currentStep === totalSteps - 1}>
                            {nextIsLoading ? (
                              <CircularProgress size={24} />
                            ) : nextError ? (
                              "Hiba"
                            ) : (
                              "Tovább"
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  ) : (
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={12}>
                        <InputLabel>
                          Az azonos lakóhellyel rendelkező házastársak vagy
                          élettársak közösen, együttes igénylőként igényelhetik
                          a támogatást. Szeretné kérni az ehhez szükséges
                          nyilatkozatot?
                        </InputLabel>
                        <Select
                          fullWidth
                          value={dropdownValues.egyuttesigenyles}
                          onChange={(e) =>
                            handleDropdownChange(
                              "egyuttesigenyles",
                              e.target.value
                            )
                          }>
                          <MenuItem value="true">Igen</MenuItem>
                          <MenuItem value="false">Nem</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <InputLabel>
                          Amennyiben a szülők lakóhelye nem azonos és
                          mindkettejük lakóhelye megegyezik legalább az egyik
                          gyermek lakóhelyével, és az egyik szülő az őt
                          megillető támogatási részről lemond a másik szülő
                          javára, a lemondó szülő nyilatkozata kötelező.
                          Szeretné kérni az ehhez szükséges nyilatkozatot?
                        </InputLabel>
                        <Select
                          fullWidth
                          value={dropdownValues.lemondo}
                          onChange={(e) =>
                            handleDropdownChange("lemondo", e.target.value)
                          }>
                          <MenuItem value="true">Igen</MenuItem>
                          <MenuItem value="false">Nem</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <InputLabel>
                          Nagykorú gyermek(ek) esetén szükséges nyilatkozatot
                          kitölteni. Szeretné kérni az ehhez szükséges
                          nyilatkozatot?
                        </InputLabel>
                        <Select
                          fullWidth
                          value={dropdownValues.nagykoru}
                          onChange={(e) =>
                            handleDropdownChange("nagykoru", e.target.value)
                          }>
                          <MenuItem value="true">Igen</MenuItem>
                          <MenuItem value="false">Nem</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <InputLabel>
                          Ha a felújításra, korszerűsítésre került ingatlanban
                          valamely igénylő szülője tulajdoni hányaddal
                          rendelkezik, kötelező erről nyilatkozat kitöltése.
                          Szeretné kérni az ehhez szükséges nyilatkozatot?
                        </InputLabel>
                        <Select
                          fullWidth
                          value={dropdownValues.hozzajarulo}
                          onChange={(e) =>
                            handleDropdownChange("hozzajarulo", e.target.value)
                          }>
                          <MenuItem value="true">Igen</MenuItem>
                          <MenuItem value="false">Nem</MenuItem>
                        </Select>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <Button
                          variant="contained"
                          color={"primary"}
                          className="button-fix"
                          onClick={handleSzuksegesDokumentumokNext}
                          style={{
                            color: nextError ? "red !important" : "black",
                            outline: nextError ? "2px solid red" : "",
                          }}
                          disabled={currentStep === totalSteps - 1}>
                          {nextIsLoading ? (
                            <CircularProgress size={24} />
                          ) : nextError ? (
                            "Hiba"
                          ) : (
                            "Tovább"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </LocalizationProvider>
              </ThemeProvider>
            </div>
          </div>
        </div>

        <div className="solar__footer" id="kapcsolat">
          <div className="solar__footer__container">
            <h2>Green Island Invest Zrt.</h2>
            <div style={{ fontSize: "0.8rem" }}>
              <p>2074 Perbál Ipari Park 17</p>
              <p>info@greenislandinvest.hu</p>
              <p>+36 1 300 9240</p>
              <p>+36 70 / 315-7070</p>
              <p>+36 70 / 309-7070</p>
            </div>
            <div className="solar__footer__container__btns">
              <button
                className="btn-secondary-inverse"
                onClick={() => chatButton.current.openChat()}>
                Visszahívást kérek
              </button>
            </div>
          </div>
        </div>
      </div>
      <FloatingChatButton ref={chatButton} />
    </>
  );
};

export default OtthonFelujitasKitoltes;
