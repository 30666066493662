import { CheckBoxRounded, ErrorRounded, FileCopy } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { default as React, useEffect, useState } from "react";
import config from "../../contexts/config";
import "../arajanlatozo/arajanlatozo.scss";
import "../solar/solar-model.scss";

const OtthonFelujitas = () => {
  const [password, setPassword] = useState("");
  const [link, setLink] = useState("");
  const [formData, setFormData] = useState({
    nev: "",
    lakcim: "",
    szuletesihely: "",
    szuletesiido: null,
    anyjaneve: "",
    adoazonosito: "",
    napelemtipus: "",
    napelemdarab: 0,
    inverterdarab: 0,
    invertertipus: "",
    akkumulatortipus: "",
    akkumulatordarab: 0,
    telepiteshelye: "",
    bruttovallalkozoidij: "",
    eloleg: "",
    ertekesito: "",
    nevlegesteljesitmeny: 0,
    nettovallalkozoidij: 0,
    afa: 0,
    elolegnetto: 0,
    elolegafa: 0,
    elolegbrutto: 0,
    garanciak: [],
    telefonszam: "",
    email: "",
    ertekesitonev: "",
    ertekesitoemail: "",
    ertekesitotelefonszam: "",
    keltezesdatuma: new Date(), // Default value set to today's date
    keltezesvaros: "",
    selectedKey: "",
  });

  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false);

  useEffect(() => {
    let timer;
    if (isSuccess || isError) {
      timer = setTimeout(() => {
        setLoading(false);
        setSuccess(false);
        setError(false);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [isSuccess, isError]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const isButtonDisabled =
    !formData.nev && !formData.telefonszam && !formData.email;

  const generateRandomHash = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    // Append the current timestamp to ensure uniqueness
    result += Date.now().toString(36);
    return result;
  };

  const generateLinkAndPass = (e) => {
    setShowStatuses(false);
    e.preventDefault();
    const hash = generateRandomHash(32);
    setEgyuttesigenylesStatus("");
    setLemondoStatus("");
    setNagykoruStatus("");
    setHozzajaruloStatus("");
    setKerelemStatus("");
    const link = `https://www.greenislandinvest.hu/kitoltes/otthonfelujitas/${hash}`;
    const password = generateRandomHash(8);
    let failed = false;
    const queryParams = new URLSearchParams({
      hash,
      password,
      nev: formData.nev,
    });
    setLoading(true);
    fetch(
      `${config.API_URL_ONRENDER}/otthonfelujitas?${new URLSearchParams({
        nev: formData.nev,
      }).toString()}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          failed = false;
        } else {
          failed = true;
        }
        return response.json();
      })
      .then((data) => {
        if (data.hash) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            nev: data.Name,
          }));
          let dd = new Date(data.Szuletesiido).getDate();
          setPerson({
            ...data,
            ...{
              SzuletesiidoEv: new Date(data.Szuletesiido).getFullYear(),
            },
            ...{
              SzuletesiidoHo: (
                "0" +
                (new Date(data.Szuletesiido).getMonth() + 1)
              ).slice(-2),
            },
            ...{ SzuletesiidoNap: dd < 10 ? "0" + dd : dd },
            ...{
              ElettarsszuletesiidoEv: new Date(
                data.Elettarsszuletesiido
              ).getFullYear(),
            },
            ...{
              ElettarsszuletesiidoHo: (
                "0" +
                (new Date(data.Elettarsszuletesiido).getMonth() + 1)
              ).slice(-2),
            },
            ...{
              ElettarsszuletesiidoNap:
                new Date(data.Elettarsszuletesiido).getDate() < 10
                  ? "0" + new Date(data.Elettarsszuletesiido).getDate()
                  : new Date(data.Elettarsszuletesiido).getDate(),
            },
            ...{
              Gyermek1szuletesiidoEv: new Date(
                data.Gyermekszuletesiido
              ).getFullYear(),
            },
            ...{
              Gyermek1szuletesiidoHo: (
                "0" +
                (new Date(data.Gyermekszuletesiido).getMonth() + 1)
              ).slice(-2),
            },
            ...{
              Gyermek1szuletesiidoNap:
                new Date(data.Gyermekszuletesiido).getDate() < 10
                  ? "0" + new Date(data.Gyermekszuletesiido).getDate()
                  : new Date(data.Gyermekszuletesiido).getDate(),
            },
            ...{
              elsoVegzo: data.Felujitasokatvegzo.includes(
                "vállalkozó nem közeli hozzátartozóm és nem élettársam"
              )
                ? "igen"
                : "nem",
            },
            ...{
              masodikVegzo: data.Felujitasokatvegzo.includes(
                "gazdálkodó szervezetnek sem én, sem közeli hozzátartozóm, élettársam nem tagja, nem munkavállalója és nem vezető tisztségviselője"
              )
                ? "igen"
                : "nem",
            },
            ...{
              harmadikVegzo: data.Felujitasokatvegzo.includes(
                "együttes igénylés esetén: mindkét feltétel mindkét igénylő esetében teljesül"
              )
                ? "igen"
                : "nem",
            },
            ...{
              GyermekapjaszuletesiidoEv: new Date(
                data.Gyermekapjaszuletesiido
              ).getFullYear(),
            },
            ...{
              GyermekapjaszuletesiidoHo: (
                "0" +
                (new Date(data.Gyermekapjaszuletesiido).getMonth() + 1)
              ).slice(-2),
            },
            ...{
              GyermekapjaszuletesiidoNap:
                new Date(data.Gyermekapjaszuletesiido).getDate() < 10
                  ? "0" + new Date(data.Gyermekapjaszuletesiido).getDate()
                  : new Date(data.Gyermekapjaszuletesiido).getDate(),
            },
            ...{
              GyermekapjaanyaszuletesiidoEv: new Date(
                data.Gyermekapjaanyaszuletesiido
              ).getFullYear(),
            },
            ...{
              GyermekapjaanyaszuletesiidoHo: (
                "0" +
                (new Date(data.Gyermekapjaanyaszuletesiido).getMonth() + 1)
              ).slice(-2),
            },
            ...{
              GyermekapjaanyaszuletesiidoNap:
                new Date(data.Gyermekapjaanyaszuletesiido).getDate() < 10
                  ? "0" + new Date(data.Gyermekapjaanyaszuletesiido).getDate()
                  : new Date(data.Gyermekapjaanyaszuletesiido).getDate(),
            },
            ...{
              SzuloszuletesiidoEv: new Date(
                data.Szuloszuletesiido
              ).getFullYear(),
            },
            ...{
              SzuloszuletesiidoHo: (
                "0" +
                (new Date(data.Szuloszuletesiido).getMonth() + 1)
              ).slice(-2),
            },
            ...{
              SzuloszuletesiidoNap:
                new Date(data.Szuloszuletesiido).getDate() < 10
                  ? "0" + new Date(data.Szuloszuletesiido).getDate()
                  : new Date(data.Szuloszuletesiido).getDate(),
            },
            ...{
              Igenyloszamlaszamfirst8: data.Igenyloszamlaszam.slice(0, 8),
            },
            ...{
              Igenyloszamlaszam8to16: data.Igenyloszamlaszam.slice(8, 16),
            },
            ...{
              Igenyloszamlaszam16to24: data.Igenyloszamlaszam.slice(16, 24),
            },
            ...{
              Vettigenybemeglevolakasfelujitasara: "igen",
            },
            ...{
              formazottSzuletesiido: new Date(
                data.Szuletesiido
              ).toLocaleDateString("hu-HU"),
            },
            ...{
              formazottLemondoszuloszuletesiido: new Date(
                data.Lemondoszuloszuletesiido
              ).toLocaleDateString("hu-HU"),
            },
            ...{
              Tanulmanyokmegkezdesenekideje: new Date(
                data.Tanulmanyokmegkezdesenekideje
              ).toLocaleDateString("hu-HU"),
            },
            ...{
              Tanulmanyokbefejezesenekideje: new Date(
                data.Tanulmanyokbefejezesenekideje
              ).toLocaleDateString("hu-HU"),
            },
          });

          setSuccess(true);
          setLoading(false);
          setLink(
            `https://www.greenislandinvest.hu/kitoltes/otthonfelujitas/${data.hash}`
          );
          setPassword(data.password);
        } else {
          setError(true);
          setLoading(false);
          fetch(
            `${
              config.API_URL_ONRENDER
            }/saveOtthonfelujitas?${queryParams.toString()}`,
            {
              method: "POST",
              headers: {
                accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.token}`,
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              setSuccess(true);
              setLoading(false);
            })
            .catch((error) => {
              setError(true);
              setLoading(false);
              console.error("Error:", error);
            });
          setLink(link);
          setPassword(password);
        }
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
        fetch(
          `${
            config.API_URL_ONRENDER
          }/saveOtthonfelujitas?${queryParams.toString()}`,
          {
            method: "POST",
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setSuccess(true);
            setLoading(false);
          })
          .catch((error) => {
            setError(true);
            setLoading(false);
            console.error("Error:", error);
          });
        setLink(link);
        setPassword(password);
        console.error("Error:", error);
      });
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link).then(() => {
      alert("Link kimásolva!");
    });
  };

  const handleCopyPassword = () => {
    navigator.clipboard.writeText(password).then(() => {
      alert("Jelszó kimásolva!");
    });
  };

  const [person, setPerson] = useState(null);
  const [egyuttesigenylesStatus, setEgyuttesigenylesStatus] = useState("");
  const [lemondoStatus, setLemondoStatus] = useState("");
  const [nagykoruStatus, setNagykoruStatus] = useState("");
  const [hozzajaruloStatus, setHozzajaruloStatus] = useState("");
  const [kerelemStatus, setKerelemStatus] = useState("");
  const [showStatuses, setShowStatuses] = useState(false);

  const handleAddressButtonClick = () => {
    setShowStatuses(true);
    setLoading(true);
    setSuccess(false);
    setError(false);
    setEgyuttesigenylesStatus("");
    setLemondoStatus("");
    setNagykoruStatus("");
    setHozzajaruloStatus("");
    setKerelemStatus("");
    const jsonData = {
      template_id: "oyEoqAuSudSQ7Z3tq7DB",
      data: person,
      file: "",
      sheet: "",
      name: "",
      output_name: person.nev + " kérelem",
      output_format: ".pdf",
      output_quality: 100,
      enchanced_syntax: true,
      single_file: true,
      page_break: true,
      merge_with: "",
    };

    const params = new URLSearchParams({
      id: person.Id,
    });

    setKerelemStatus("loading");
    fetch("https://api.docugenerate.com/v1/document", {
      method: "POST",
      headers: {
        accept: "application/json",
        Authorization: "945c5c49b7f73ff286550a920c167eba",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(jsonData),
    })
      .then((response) => response.json())
      .then((data) => {
        setSuccess(true);
        setLoading(false);
        if (data.document_uri) {
          if (downloadDocument) {
            window.location.href = data.document_uri;
          }
          const requestBody = {
            Kerelem: data.document_uri,
            StatusId: 3667,
          };

          fetch(
            `${
              config.API_URL_ONRENDER
            }/minicrm/uploadFile?${params.toString()}`,
            {
              method: "PUT",
              headers: {
                accept: "application/json",
                Authorization: `Bearer ${localStorage.token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestBody),
            }
          )
            .then((response) => {
              if (!response.ok) {
                setKerelemStatus("error");
              }
              return response.json();
            })
            .then(() => {
              setKerelemStatus("success");
            })
            .catch((error) => {
              setKerelemStatus("error");

              console.error("Error:", error);
            });
        } else {
          console.log("Error:", data);
          setKerelemStatus("error");
        }
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
        console.error("Error:", error);
        setKerelemStatus("error");
      });

    if (person.Szuksegesdokumentumok.includes("egyuttesigenyles")) {
      const egyuttesJson = {
        template_id: "zrsGt5vaKYEdbMWgbzd5",
        data: person,
        file: "",
        sheet: "",
        name: "",
        output_name: person.nev + " együttes igénylés",
        output_format: ".pdf",
        output_quality: 100,
        enchanced_syntax: true,
        single_file: true,
        page_break: true,
        merge_with: "",
      };
      setEgyuttesigenylesStatus("loading");
      fetch("https://api.docugenerate.com/v1/document", {
        method: "POST",
        headers: {
          accept: "application/json",
          Authorization: "945c5c49b7f73ff286550a920c167eba",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(egyuttesJson),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.document_uri) {
            if (downloadDocument) {
              window.location.href = data.document_uri;
            }
            const requestBody = {
              EgyuttesIgenyles: data.document_uri,
            };

            fetch(
              `${
                config.API_URL_ONRENDER
              }/minicrm/uploadFile?${params.toString()}`,
              {
                method: "PUT",
                headers: {
                  accept: "application/json",
                  Authorization: `Bearer ${localStorage.token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
              }
            )
              .then((response) => {
                if (!response.ok) {
                  setEgyuttesigenylesStatus("error");
                }
                return response.json();
              })
              .then(() => {
                setEgyuttesigenylesStatus("success");
              })
              .catch((error) => {
                setEgyuttesigenylesStatus("error");

                console.error("Error:", error);
              });
          } else {
            console.log("Error:", data);
            setEgyuttesigenylesStatus("error");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setEgyuttesigenylesStatus("error");
        });
    }
    if (person.Szuksegesdokumentumok.includes("lemondo")) {
      const egyuttesJson = {
        template_id: "CC5QgYw0Xi2FTUPZQnhO",
        data: person,
        file: "",
        sheet: "",
        name: "",
        output_name: person.nev + " lemondó",
        output_format: ".pdf",
        output_quality: 100,
        enchanced_syntax: true,
        single_file: true,
        page_break: true,
        merge_with: "",
      };
      setLemondoStatus("loading");
      fetch("https://api.docugenerate.com/v1/document", {
        method: "POST",
        headers: {
          accept: "application/json",
          Authorization: "945c5c49b7f73ff286550a920c167eba",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(egyuttesJson),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.document_uri) {
            if (downloadDocument) {
              window.location.href = data.document_uri;
            }
            const requestBody = {
              LemondoSzuloiNyilatkozat: data.document_uri,
            };

            fetch(
              `${
                config.API_URL_ONRENDER
              }/minicrm/uploadFile?${params.toString()}`,
              {
                method: "PUT",
                headers: {
                  accept: "application/json",
                  Authorization: `Bearer ${localStorage.token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
              }
            )
              .then((response) => {
                if (!response.ok) {
                  setLemondoStatus("error");
                }
                return response.json();
              })
              .then(() => {
                setLemondoStatus("success");
              })
              .catch((error) => {
                setLemondoStatus("error");

                console.error("Error:", error);
              });
          } else {
            console.log("Error:", data);
            setLemondoStatus("error");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setLemondoStatus("error");
        });
    }

    if (person.Szuksegesdokumentumok.includes("nagykoru")) {
      const egyuttesJson = {
        template_id: "jtUaJf4vDjo6VpRGebkb",
        data: person,
        file: "",
        sheet: "",
        name: "",
        output_name: person.nev + " nagykorú",
        output_format: ".pdf",
        output_quality: 100,
        enchanced_syntax: true,
        single_file: true,
        page_break: true,
        merge_with: "",
      };
      setNagykoruStatus("loading");
      fetch("https://api.docugenerate.com/v1/document", {
        method: "POST",
        headers: {
          accept: "application/json",
          Authorization: "945c5c49b7f73ff286550a920c167eba",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(egyuttesJson),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.document_uri) {
            if (downloadDocument) {
              window.location.href = data.document_uri;
            }
            const requestBody = {
              NagykoruHozzajarulas: data.document_uri,
            };

            fetch(
              `${
                config.API_URL_ONRENDER
              }/minicrm/uploadFile?${params.toString()}`,
              {
                method: "PUT",
                headers: {
                  accept: "application/json",
                  Authorization: `Bearer ${localStorage.token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
              }
            )
              .then((response) => {
                if (!response.ok) {
                  setNagykoruStatus("error");
                }
                return response.json();
              })
              .then(() => {
                setNagykoruStatus("success");
              })
              .catch((error) => {
                setNagykoruStatus("error");

                console.error("Error:", error);
              });
          } else {
            console.log("Error:", data);
            setNagykoruStatus("error");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setNagykoruStatus("error");
        });
    }
    if (person.Szuksegesdokumentumok.includes("hozzajarulo")) {
      const egyuttesJson = {
        template_id: "cS3YcUbC7c27GGpEZ0Hb",
        data: person,
        file: "",
        sheet: "",
        name: "",
        output_name: person.nev + " szülő hozzájáruló",
        output_format: ".pdf",
        output_quality: 100,
        enchanced_syntax: true,
        single_file: true,
        page_break: true,
        merge_with: "",
      };
      setHozzajaruloStatus("loading");
      fetch("https://api.docugenerate.com/v1/document", {
        method: "POST",
        headers: {
          accept: "application/json",
          Authorization: "945c5c49b7f73ff286550a920c167eba",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(egyuttesJson),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.document_uri) {
            if (downloadDocument) {
              window.location.href = data.document_uri;
            }
            const requestBody = {
              SzuloiHozzajarulas: data.document_uri,
            };

            fetch(
              `${
                config.API_URL_ONRENDER
              }/minicrm/uploadFile?${params.toString()}`,
              {
                method: "PUT",
                headers: {
                  accept: "application/json",
                  Authorization: `Bearer ${localStorage.token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
              }
            )
              .then((response) => {
                if (!response.ok) {
                  setHozzajaruloStatus("error");
                }
                return response.json();
              })
              .then(() => {
                setHozzajaruloStatus("success");
              })
              .catch((error) => {
                setHozzajaruloStatus("error");

                console.error("Error:", error);
              });
          } else {
            console.log("Error:", data);
            setHozzajaruloStatus("error");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setHozzajaruloStatus("error");
        });
    }
  };

  const [downloadDocument, setDownloadDocument] = useState(false);

  const handleCheckboxChange = (event) => {
    setDownloadDocument(event.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} lg={12}>
            Megnyitotta{" "}
            <CheckCircleRounded
              style={{
                color: "green",
                verticalAlign: "top",
                marginRight: "24px",
              }}
            />
            Kitöltötte{" "}
            <ErrorRounded style={{ color: "red", verticalAlign: "top" }} />
          </Grid> */}
          <Grid item xs={12} lg={12}>
            <TextField
              name="nev"
              label="Név"
              fullWidth
              value={formData.nev}
              onChange={handleChange}
            />
          </Grid>
          {password && link ? (
            <Grid item xs={12} lg={6}>
              <p>
                <Tooltip title="Link másolása vágólapra">
                  <IconButton onClick={handleCopyLink}>
                    <FileCopy />
                  </IconButton>
                </Tooltip>
                A felhasználó linkje: <strong>...{link.slice(-56)}</strong>
              </p>
              <p>
                <Tooltip title="Jelszó másolása vágólapra">
                  <IconButton onClick={handleCopyPassword}>
                    <FileCopy />
                  </IconButton>
                </Tooltip>
                A felhasználó jelszava: <strong>{password}</strong>
              </p>
            </Grid>
          ) : (
            <></>
          )}
          {person ? (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={downloadDocument}
                    onChange={handleCheckboxChange}
                    color="primary"
                  />
                }
                label="Dokumentumok letöltése"
              />
            </Grid>
          ) : (
            <></>
          )}

          <Grid
            item
            xs={12}
            sx={{
              justifyContent: "center",
              display: "flex",
              flexWrap: "wrap",
              rowGap: 2,
              columnGap: 5,
            }}>
            <Button
              variant="contained"
              color="primary"
              disabled={isButtonDisabled || isLoading}
              onClick={generateLinkAndPass}>
              {isLoading ? <CircularProgress size={24} /> : "Link generálása"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                isButtonDisabled ||
                isLoading ||
                !person ||
                !link ||
                !password ||
                lemondoStatus === "loading" ||
                egyuttesigenylesStatus === "loading" ||
                nagykoruStatus === "loading" ||
                hozzajaruloStatus === "loading" ||
                kerelemStatus === "loading" ||
                !formData.nev
              }
              onClick={handleAddressButtonClick}>
              {isLoading ||
              lemondoStatus === "loading" ||
              egyuttesigenylesStatus === "loading" ||
              nagykoruStatus === "loading" ||
              hozzajaruloStatus === "loading" ||
              kerelemStatus === "loading" ? (
                <CircularProgress size={24} />
              ) : (
                "Dokumentumok generálása"
              )}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isButtonDisabled || isLoading}
              onClick={() => {
                setFormData((prev) => ({
                  ...prev,
                  nev: "",
                }));
                setLink("");
                setPerson(null);
                setPassword("");
              }}>
              Törlés
            </Button>
          </Grid>
          {showStatuses ? (
            <Grid
              item
              xs={12}
              sx={{
                justifyContent: "center",
                display: "flex",
                flexWrap: "wrap",
                rowGap: 2,
                columnGap: 5,
              }}>
              <p style={{ width: "100%", textAlign: "center" }}>
                Kérelem:{" "}
                {kerelemStatus === "success" ? (
                  <CheckBoxRounded style={{ verticalAlign: "bottom" }} />
                ) : kerelemStatus === "loading" ? (
                  <CircularProgress
                    size={24}
                    style={{ verticalAlign: "bottom" }}
                  />
                ) : (
                  <ErrorRounded style={{ verticalAlign: "bottom" }} />
                )}
              </p>
              {person?.Szuksegesdokumentumok.includes("egyuttesigenyles") ? (
                <p>
                  Együttesigénylés:{" "}
                  {egyuttesigenylesStatus === "success" ? (
                    <CheckBoxRounded style={{ verticalAlign: "bottom" }} />
                  ) : egyuttesigenylesStatus === "loading" ? (
                    <CircularProgress
                      size={24}
                      style={{ verticalAlign: "bottom" }}
                    />
                  ) : (
                    <ErrorRounded style={{ verticalAlign: "bottom" }} />
                  )}
                </p>
              ) : (
                <></>
              )}
              {person?.Szuksegesdokumentumok.includes("lemondo") ? (
                <p style={{ width: "100%", textAlign: "center" }}>
                  Lemondó szülő:{" "}
                  {lemondoStatus === "success" ? (
                    <CheckBoxRounded style={{ verticalAlign: "bottom" }} />
                  ) : lemondoStatus === "loading" ? (
                    <CircularProgress
                      size={24}
                      style={{ verticalAlign: "bottom" }}
                    />
                  ) : (
                    <ErrorRounded style={{ verticalAlign: "bottom" }} />
                  )}
                </p>
              ) : (
                <></>
              )}
              {person?.Szuksegesdokumentumok.includes("nagykoru") ? (
                <p style={{ width: "100%", textAlign: "center" }}>
                  Nagykorú hozzájáruló:{" "}
                  {nagykoruStatus === "success" ? (
                    <CheckBoxRounded style={{ verticalAlign: "bottom" }} />
                  ) : nagykoruStatus === "loading" ? (
                    <CircularProgress
                      size={24}
                      style={{ verticalAlign: "bottom" }}
                    />
                  ) : (
                    <ErrorRounded style={{ verticalAlign: "bottom" }} />
                  )}
                </p>
              ) : (
                <></>
              )}
              {person?.Szuksegesdokumentumok.includes("hozzajarulo") ? (
                <p style={{ width: "100%", textAlign: "center" }}>
                  Hozzájáruló szülő:{" "}
                  {hozzajaruloStatus === "success" ? (
                    <CheckBoxRounded style={{ verticalAlign: "bottom" }} />
                  ) : hozzajaruloStatus === "loading" ? (
                    <CircularProgress
                      size={24}
                      style={{ verticalAlign: "bottom" }}
                    />
                  ) : (
                    <ErrorRounded style={{ verticalAlign: "bottom" }} />
                  )}
                </p>
              ) : (
                <></>
              )}
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </form>
    </LocalizationProvider>
  );
};

export default OtthonFelujitas;
