import TagManager from "@sooro-io/react-gtm-module";
import React, { useEffect } from "react";
import { getCookieConsentValue } from "react-cookie-consent";
import { Route, Routes as Switch, useLocation } from "react-router-dom";
import { AuthProvider } from "../contexts/authContext";
import Arajanlatozo from "../pages/arajanlatozo";
import ASZF from "../pages/Aszf";
import Bejelentkezes from "../pages/Bejelentkezes";
import Blog from "../pages/Blog";
import Promocio from "../pages/Blog copy";
import NotFound from "../pages/NotFound";
import Otthonfelujitasoskitoltes from "../pages/OtthonFelujitasKitoltes";
import Solar from "../pages/Solar";
import PrivateRoute from "../PrivateRoute";

const Routes = () => {
  const history = useLocation();
  const [tagmanagerActive, setTagmanagerActive] = React.useState(false);

  if (
    history.pathname !== "/bejelentkezes" &&
    history.pathname !== "/f9a3c2dd20d43eda5d3e522ba2b79dfc" &&
    history.pathname !== "/f9a3c2dd20d43eda5d3e522ba2b79dfc.html"
  ) {
    if (getCookieConsentValue("sutiBeleegyezes") === "true") {
      if (!tagmanagerActive) {
        TagManager.initialize({ gtmId: "GTM-5Q4TKR7T" });
        setTagmanagerActive(true);
      }
    }
  }

  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change
  return (
    <AuthProvider>
      <Switch>
        <Route path="/" element={<Solar />} />
        <Route path="/vallalati" element={<Solar />} />
        <Route path="/lakossagi" element={<Solar />} />
        <Route path="/aszf" element={<ASZF />} />
        <Route path="/blog/:id" element={<Blog />} />
        <Route path="/bejelentkezes" element={<Bejelentkezes />} />
        <Route path="/otthonfelujitas" element={<Promocio />} />
        <Route
          path="/kitoltes/otthonfelujitas/:id"
          element={<Otthonfelujitasoskitoltes />}
        />
        <Route
          path="/f9a3c2dd20d43eda5d3e522ba2b79dfc"
          element={<PrivateRoute element={<Arajanlatozo />} />}
        />

        <Route path="*" element={<NotFound />} />
      </Switch>
    </AuthProvider>
  );
};

export default Routes;
