import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "./contexts/authContext";

const PrivateRoute = ({ element: Component }) => {
  const { currentUser } = useContext(AuthContext);

  return currentUser ? Component : <Navigate to="/bejelentkezes" />;
};

export default PrivateRoute;
