import React, { useRef } from "react";
import GoToTop from "../../contexts/gototop";
import FloatingChatButton from "../floatingchat/FloatingChat";
import "../solar/solar-model.scss";

const ASZFModel = () => {
  const chatButton = useRef();

  return (
    <>
      <div className="solar">
        <div className="solar__section">
          <div
            className="solar__section__cover"
            id="top"
            style={{
              backgroundImage: `url(${require(`../../assets/solar/oktatoshatter.jpg`)})`,
            }}></div>
          <div className="solar__section__specs">
            <div className="solar__section__specs__div">
              <div>
                <h3 style={{ textAlign: "center", marginBottom: "2rem" }}>
                  Általános szerződési feltételek és adatkezelési tájékoztató
                </h3>
                <p style={{ textAlign: "justify" }}>
                  Az adatkezelési tájékoztató célja Jelen adatkezelési
                  tájékoztató célja a Green Island Invest Zrt. által telepítésre
                  kerülő napelem rendszerek iránt érdeklődő természetes
                  személyek, ajánlatkérők ajánlatadás céljából illetőleg
                  megrendelés esetén a létrejövő szerződésből fakadó jogok és
                  kötelezettségek teljesítése érdekében történő adatkezelésre
                  vonatkozó adatkezelési elvek összefoglalása. Adatkezelő
                  kijelenti, hogy az adatkezelésben érintettek önrendelkezési
                  joga tiszteletben tartását szem előtt tartva személyes
                  adatokat bizalmasan kezeli, és megtesz minden olyan
                  biztonsági, technikai és szervezési intézkedést, mely az
                  adatok biztonságát garantálja, megakadályozza azok
                  megsemmisülését, jogosulatlan hozzáférését, jogosulatlan
                  felhasználását és jogosulatlan megváltoztatását, jogosulatlan
                  terjesztését. Adatkezelő kiemelt figyelmet szentel a személyes
                  adatok védelmének, illetve a hatályos jogszabályok
                  betartásának. Jelen Tájékoztatóra a magyar jog, különösen az
                  információs önrendelkezési jogról és az információszabadságról
                  szóló 2011. évi CXII. törvény (Infotv.) rendelkezései,
                  valamint a természetes személyeknek a személyes adatok
                  kezelése tekintetében történő védelméről és az ilyen adatok
                  szabad áramlásáról, valamint a 95/46/EK irányelv hatályon
                  kívül helyezéséről szóló (EU) 2016/679 rendelet (GDPR)
                  irányadó. Adatkezelő jelen Adatvédelmi és Adatkezelési
                  Tájékoztatóban (a továbbiakban: Tájékoztató) tájékoztatja az
                  adatkezelés természetes személy érintettjeit (a továbbiakban:
                  Érintett) arról, hogy az Érintettek által Adatkezelő részére
                  történő adatszolgáltatás önkéntes. Személyes adatainak
                  megadásával az Érintett kifejezetten hozzájárul személyes
                  adatai kezeléséhez. Adatkezelő által üzemeltetett weboldal a
                  www.greenislandinvest.hu. Adatkezelő adatkezeléseivel
                  kapcsolatosan felmerülő adatvédelmi irányelvek folyamatosan
                  elérhetők a greenislandinvest.hu /adatkezelesi-tajekoztato
                  Fogalom meghatározások „személyes adat”: azonosított vagy
                  azonosítható természetes személyre („érintett”) vonatkozó
                  bármely információ; azonosítható az a természetes személy, aki
                  közvetlen vagy közvetett módon, különösen valamely azonosító,
                  például név, szám, helymeghatározó adat, online azonosító vagy
                  a természetes személy testi, fiziológiai, genetikai, szellemi,
                  gazdasági, kulturális vagy szociális azonosságára vonatkozó
                  egy vagy több tényező alapján azonosítható; „adatkezelés”: a
                  személyes adatokon vagy adatállományokon automatizált vagy nem
                  automatizált módon végzett bármely művelet vagy műveletek
                  összessége, így a gyűjtés, rögzítés, rendszerezés, tagolás,
                  tárolás, átalakítás vagy megváltoztatás, lekérdezés,
                  betekintés, felhasználás, közlés továbbítás, terjesztés vagy
                  egyéb módon történő hozzáférhetővé tétel útján, összehangolás
                  vagy összekapcsolás, korlátozás, törlés, illetve
                  megsemmisítés; „adatkezelő”: az a természetes vagy jogi
                  személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv,
                  amely a személyes adatok kezelésének céljait és eszközeit
                  önállóan vagy másokkal együtt meghatározza; ha az adatkezelés
                  céljait és eszközeit az uniós vagy a tagállami jog határozza
                  meg, az adatkezelőt vagy az adatkezelő kijelölésére vonatkozó
                  különös szempontokat az uniós vagy a tagállami jog is
                  meghatározhatja; „adatfeldolgozó”: az a természetes vagy jogi
                  személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv,
                  amely az adatkezelő nevében személyes adatokat
                  kezel;„címzett”: az a természetes vagy jogi személy,
                  közhatalmi szerv, ügynökség vagy bármely egyéb szerv, akivel
                  vagy amellyel a személyes adatot közlik, függetlenül attól,
                  hogy harmadik fél-e. Azon közhatalmi szervek, amelyek egy
                  egyedi vizsgálat keretében az uniós vagy a tagállami joggal
                  összhangban férhetnek hozzá személyes adatokhoz, nem
                  minősülnek címzettnek; az említett adatok e közhatalmi szervek
                  általi kezelése meg kell, hogy feleljen az adatkezelés
                  céljainak megfelelően az alkalmazandó adatvédelmi
                  szabályoknak; „az érintett hozzájárulása”: az érintett
                  akaratának önkéntes, konkrét és megfelelő tájékoztatáson
                  alapuló és egyértelmű kinyilvánítása, amellyel az érintett
                  nyilatkozat vagy a megerősítést félreérthetetlenül kifejező
                  cselekedet útján jelzi, hogy beleegyezését adja az őt érintő
                  személyes adatok kezeléséhez; Az adatkezelő adatai Név: Green
                  Island Invest Zrt. Székhely: 7621 Pécs, Citrom utca 2-6.
                  Adószám: 32140387-2-02 Cégjegyzékszám szám: 02 10 060510
                  Mobil: +36 70 315 7070 E-mail: info@greenislandinvest.hu 3.Az
                  adatkezelés célja Az adatkezelés célja az Érintettek által
                  kapcsolatfelvétel, ajánlat kérése, szolgáltatások
                  megrendelése, azok teljesítése, illetve számlakiállítás. Az
                  adatkezelés joglapja)elsődlegesen az érintett önkéntes
                  hozzájárulás az együttműködés során megjelölt feladatok
                  elvégzése érdekében, a Rendelet 6. cikk (1) bekezdés a) pontja
                  alapján, mely hozzájárulást a kapcsolatfelvételi űrlapon,
                  illetve üzenetben, adatai megküldésével adhat meg. A
                  hozzájárulást nem igénylő webes sütik tekintetében az
                  adatkezelés jogalapja Adatkezelő jogos érdeke (GDPR 6. cikk
                  (1) bekezdés f) pont), az elektronikus kereskedelmi
                  szolgáltatások, valamint az információs társadalmi
                  szolgáltatások egyes kérdéseiről szóló 2001. CVIII. törvény
                  (Elkertv.) 13/A. § (3) bekezdésében foglalt rendelkezésre
                  tekintettel, mely szerint a webes szolgáltató a szolgáltatás
                  nyújtása céljából kezelheti azon személyes adatokat, amelyek a
                  szolgáltatás nyújtásához technikailag elengedhetetlenül
                  szükségese Alapelvek adatkezelés során Személyes adat akkor
                  kezelhető, ha a) ahhoz az érintett hozzájárul, vagy b) azt
                  törvény vagy törvény felhatalmazása alapján, az abban
                  meghatározott körben helyi önkormányzat rendelete elrendeli.
                  Személyes adatot kezelni csak meghatározott célból, jog
                  gyakorlása és kötelezettség teljesítése érdekében lehet. Az
                  adatkezelésnek minden szakaszában meg kell felelnie e célnak.
                  Csak olyan személyes adat kezelhető, amely az adatkezelés
                  céljának megvalósulásához elengedhetetlen, a cél elérésére
                  alkalmas, csak a cél megvalósulásához szükséges mértékben és
                  ideig.Személyes adat csak megfelelő tájékoztatáson alapuló
                  beleegyezéssel kezelhető. Az érintettet egyértelműen,
                  közérthetően és részletesen tájékoztatni kell az adatai
                  kezelésével kapcsolatos minden tényről, így különösen az
                  adatkezelés céljáról és jogalapjáról, az adatkezelésre és az
                  adatfeldolgozásra jogosult személyéről, az adatkezelés
                  időtartamáról, illetve arról, hogy kik ismerhetik meg az
                  adatokat. A tájékoztatásnak ki kell terjednie az érintett
                  adatkezeléssel kapcsolatos jogaira és jogorvoslati
                  lehetőségeire is. A kezelt személyes adatoknak meg kell
                  felelniük az alábbi követelményeknek: a) felvételük és
                  kezelésük tisztességes és törvényes; b) pontosak, teljesek és
                  ha szükséges, időszerűek; c) tárolásuk módja alkalmas arra,
                  hogy az érintettet csak a tárolás céljához szükséges ideig
                  lehessen azonosítani. Korlátozás nélkül használható, általános
                  és egységes személyazonosító jel alkalmazása tilos. A
                  személyes adatok akkor továbbíthatók, valamint a különböző
                  adatkezelések akkor kapcsolhatók össze, ha az érintett ahhoz
                  hozzájárult, vagy törvény azt megengedi, és ha az adatkezelés
                  feltételei minden egyes személyes adatra nézve teljesülnek. A
                  Személyes adatokat a jóhiszeműség és a tisztesség és
                  átláthatóság elveinek, valamint a hatályos jogszabályok és
                  jelen Tájékoztatóban rendelkezéseinek megfelelően kezeljük. A
                  személyes adatok kezelésére kizárólag célhoz kötötten kerül
                  sor. A személyes adatokat az eredeti céljuktól eltérő célra
                  nem használjuk fel, kivéve, ha az Érintett ehhez kifejezett
                  hozzájárulását adja. Az adatok statisztikailag összesített
                  formában történő felhasználása nem minősül Adatkezelésnek, sem
                  adattovábbításnak, tekintettel arra, hogy az Érintett
                  beazonosítására alkalmas egyéb adatot semmilyen formában nem
                  tartalmazhat. A fenti rendelkezésekre tekintettel Adatkezelő,
                  a tudomására jutott adatokat a GDPR-ban, az Info tv.-ben és
                  jelen tájékoztatóban rögzített adatvédelmi elveknek
                  megfelelően kezeli, és azokat harmadik félnek – a jelen
                  tájékoztatóban meghatározott adatkezelők és külső szolgáltatók
                  kivételével – nem adja át. Amennyiben Adatkezelő a Személyes
                  adatokat az eredeti adatfelvétel céljától eltérő célra kívánja
                  felhasználni, erről az Érintettet tájékoztatja az Érintett
                  tájékoztatásra kerül, és beszerzi Érintett előzetes,
                  kifejezett hozzájárulását kérem, illetőleg lehetőséget
                  biztosítok számára, hogy a fenti adatok felhasználást
                  megtiltsa. A megadott Személyes adatok nem kerülnek
                  ellenőrzésre. A megadott Személyes adatok megfelelőségéért
                  kizárólag az azt megadó személy felel. Az Adatkezelő által
                  üzemeltetett weboldal az Érintett aktivitásáról adatokat
                  gyűjthet, melyek nem kapcsolhatók össze az Érintett által a
                  kapcsolatfelvételi űrlapon megadott egyéb adatokkal, sem más
                  honlapok vagy szolgáltatások igénybevételekor keletkező
                  adatokkal. Cookie-k (Sütik) 6.1 A sütik feladataA sütik
                  információkat gyűjtenek a látogatókról és eszközeikről;
                  megjegyzik a látogatók egyéni beállításait, amelyek
                  felhasználásra kerül(het)nek pl. online tranzakciók
                  igénybevételekor, így nem kell újra begépelni őket;
                  megkönnyítik a weboldal használatát; minőségi felhasználói
                  élményt biztosítanak, továbbá részt vesznek néhány látogatói
                  statisztikai információ gyűjtésében. A testre szabott
                  kiszolgálás érdekében a felhasználó számítógépén kis
                  adatcsomagot, ún. sütit (cookie) helyez el és a későbbi
                  látogatás során olvas vissza. Ha a böngésző visszaküld egy
                  korábban elmentett sütit, a sütit kezelő szolgáltatónak
                  lehetősége van összekapcsolni a felhasználó aktuális
                  látogatását a korábbiakkal, de kizárólag a saját tartalma
                  tekintetében. A sütik egy része nem tartalmaz az egyéni
                  felhasználó azonosításra alkalmas, személyes információkat,
                  egy részük tartalmaz egy titkos, véletlenszerűen generált
                  számsort, melyet a felhasználó eszköze eltárol és a
                  felhasználó azonosíthatóságát biztosítja. 6.2. Feltétlenül
                  szükséges, munkamenet (session) cookie-k Ezen sütik célja,
                  hogy a látogatók maradéktalanul és zökkenőmentesen
                  böngészhessék a greenislandinvest.hu weboldalát, használhassák
                  annak funkcióit, és az ott elérhető szolgáltatásokat. Az ilyen
                  típusú sütik érvényességi ideje a munkamenet (böngészés)
                  befejezéséig tart, a böngésző bezárásával a sütik e fajtája
                  automatikusan törlődik a számítógépről, illetve a böngészésre
                  használt más eszközről. 6.3. Harmadik fél által elhelyezett
                  cookie-k (analitika) A greenislandinvest.hu és aldomain-jeik
                  weboldalain alkalmazza a Google Analytics mint harmadik fél
                  sütijeit is. A Google Analytics statisztikai célú szolgáltatás
                  használatával az greeninvestisland.hu és aldomain-jeik
                  weboldalai információkat gyűjtenek azzal kapcsolatban, hogy a
                  látogatók hogyan használják a weboldalakat. Az adatot a honlap
                  fejlesztésének és a felhasználói élmény javításának céljával
                  használja fel. Ezen sütik szintén lejáratukig a látogató
                  számítógépén vagy böngészésre használt más eszközén, annak
                  böngészőjében maradnak, illetve amíg a látogató nem törli
                  őket. 6.4. A cookie kezelés jogalapja A cookie kezelés
                  jogalapja a weboldal látogatójának hozzájárulása, a vonatkozó
                  Rendelet 6. cikk (1) bekezdés a) pontja alapján. Amennyiben
                  nem fogadja el a cookie-k használatát, akkor Adatkezelő
                  weboldalának bizonyos funkciói nem lesznek elérhetőek a
                  weboldalak használata során, illetve előfordulhat, hogy
                  bizonyos funkciók hibásan működnek.A cookie-k törléséről
                  bővebb információt a gyakoribb böngészők tekintetében az
                  alábbi linkeken találhat: Firefox: Weboldalak által
                  elhelyezett sütik törlése a számítógépről Chrome: Clear cache
                  & cookies Safari: Manage cookies and website data in Safari on
                  Mac Adatkezelő által kezelt adatok, az adatkezelés jogalapja,
                  az adatkezelés időtartama Adatkezelő tevékenységének
                  adatkezelései önkéntes hozzájáruláson, illetve törvényi
                  felhatalmazáson alapulnak. Az önkéntes hozzájáruláson alapuló
                  adatkezelések esetében az érintettek e hozzájárulásukat az
                  adatkezelés bármely szakában visszavonhatják. Bizonyos
                  esetekben – pédául a számlázás során- a megadott adatok egy
                  körének kezelését, tárolását, továbbítását jogszabályok teszik
                  kötelezővé, melyről külön értesítjük ügyfeleinket. Ezen
                  jogszabályok az Európai Parlament és a Tanács (EU) 2016/679
                  rendelete (2016. április 27.) – a természetes személyeknek a
                  személyes adatok kezelése tekintetében történő védelméről és
                  az ilyen adatok szabad áramlásáról, valamint a 95/46/EK
                  rendelet hatályon kívül helyezéséről (általános adatvédelmi
                  rendelet, GDPR); a 2013. évi V. törvény – a Polgári
                  Törvénykönyvről (Ptk.); a 2000. évi C. törvény – a
                  számvitelről (Számv. tv.); évi LIII. törvény – a pénzmosás és
                  terrorizmus finanszírozása megelőzéséről és megakadályozásáról
                  (Pmt.); évi CCXXXVII. törvény – a hitelintézetekről és a
                  pénzügyi vállalkozásokról (Hpt.). 7.1 Online , illetőleg
                  telefonos kapcsolatfelvételhez, ajánlatkéréshez,
                  megrendeléshez kapcsolódó adatok Név Email cím Telefonszám Az
                  adatkezelés célja: az érdeklődők informálása, kérdéseik
                  megválaszolása, ajánlattétel, megrendelés teljesítése Az
                  adatkezelés jogalapja az önkéntes hozzájárulás Megőrzési idő:
                  üzleti kapcsolat időtartamától számított 5 év (Ptk szerinti
                  elévülési idő), vagy a hozzájárulás visszavonása7.2 Hírlevél /
                  eDM-hez kapcsolódó adatok A hírlevél feliratkozás során elkért
                  személyes adatok: Név Email cím A hírlevél kiküldésére az
                  adatkezelési szabályzat megismerését és elfogadását követően
                  van lehetőség. Az adatkezelési szabályzat elfogadása előre ki
                  nem töltött, kötelező jelölőnégyzet elfogadásával történik. A
                  feliratkozást követően a feliratkozó egy email üzenetben
                  tájékoztatást kap a feliratkozásról, melyet meg is kell
                  erősítenie, a feliratkozása ezt követően lép hatályba. Minden
                  ezzel kapcsolatos folyamat során az ügyfélnek továbbra is
                  lehetősége van a leiratkozásra, a leiratkozás lehetőségét
                  minden levél egy link formájában tartalmazza, az egy
                  klikkeléssel könnyen elérhető. Mivel a hírlevél tartalmazza a
                  vállalkozás nevét és weboldalának elérhetőségét, így
                  reklámnak, eDM-nek minősül. Az adatkezelés célja :Adatkezelő
                  tevékenységének, aktuális akcióinak, projektjeinek
                  népszerűsítése. Az adatkezelés jogalapja önkéntes
                  hozzájárulás. az adatkezelés időtartama: leiratkozásig. 7.3.
                  Ügyfél kapcsolati adatok Az ügyfelek cégvezetőinek és
                  kapcsolattartóinak alábbi személyes adatait és elérhetőségei
                  kerülnek : Név Email cím Telefonszám Az adatkezelés célja:
                  Kapcsolatfelvétel és kapcsolattartás Az adatkezelés jogalapja:
                  hozzájárulás Az adatkezelés időtartama: üzleti kapcsolat
                  időtartamát követő 5 év (Ptk elévülési idő), illetőleg a
                  hozzájárulás visszavonása 7.4. Számlázás céljából történő
                  adatkezelés A szolgáltatások ellenértékének megfizetésére
                  kiállított számlán feltüntetendő adatok kezelése:A kezelt
                  adatok köre: megrendelő neve, címe, esetlegesen megrendelő
                  aláírása, számla kibocsátójának aláírása Az adatkezelés
                  időtartama: jogszabály szerint (számvitelről szóló 2000. évi
                  C. törvény 169.§-a) 7.5. Álláspályázatokkal kapcsolatos
                  adatkezelés A Green Island Invest Zrt. részére benyújtott
                  álláspályázatokban lévő személyes adatokat Adatkezelő az
                  Érintettek hozzájárulása alapján kezeli. Adatkezelő az
                  álláspályázatokat az adatkezeléshez történő hozzájárulás
                  megadását követően kezeli, annak érdekében, hogy az
                  álláspályázatokat elbírálja. Amennyiben Adatkezelő olyan
                  pozícióba újabb pályázót keres, amelynek a pályázó
                  megfelelhet, úgy a pályázati anyagokat ilyen esetben
                  felhasználhatja. Az állásra pályázók azon személyazonosító és
                  kapcsolattartási adatait kezeli Adatkezelő, amelyet a pályázó
                  a kapcsolatfelvétel során vagy pályázatában megad, a
                  pályázatok elbírálása céljából, különösen a pályázó nevét,
                  lakcímét, e-mail címét, telefonszámát, végzettségét és szakmai
                  tapasztalatára vonatkozó adatait kezeli. Az adatkezelés
                  időtartama a hozzájárulás visszavonása. 7.6. Panaszkezelés
                  céljából kezelt adatok: A bejelentett reklamáció kivizsgálása
                  és a felhasználó értesítése céljából kezelt adatok A kezelt
                  adatok köre: a felhasználó neve, lakcíme, a panasz
                  előterjesztésének helye, ideje, módja, a fogyasztó panaszának
                  részletes leírása, a fogyasztó által bemutatott iratok,
                  dokumentumok és egyéb bizonyítékok jegyzéke, a jegyzőkönyvet
                  felvevő személy és – telefonon vagy egyéb elektronikus
                  hírközlési szolgáltatás felhasználásával közölt szóbeli panasz
                  kivételével – a fogyasztó aláírása, a jegyzőkönyv felvételének
                  helye, ideje, telefonon vagy egyéb elektronikus hírközlési
                  szolgáltatás felhasználásával közölt szóbeli panasz esetén a
                  panasz egyedi azonosítószáma. Az adatkezelés időtartama:
                  jogszabály szerint 5 évig (1997. évi CLV. törvény a
                  fogyasztóvédelemről 17/A. § (7) bekezdés) 7.7. Egyéb
                  adatkezelések A jelen Tájékoztatóban fel nem sorolt
                  adatkezelésekről az Érintetteket közvetlenül tájékoztatjuk az
                  irányadó szabályok alapján. Adatkezelő a jogszabályban
                  meghatározott esetekben az általa kezelt személyes adatokat
                  kiadhatja. Személyes adatok harmadik személynek vagy hatóságok
                  számára történő kiadása – hacsak jogszabályettől eltérően nem
                  rendelkezik – kizárólag hatósági határozat alapján, vagy az
                  Érintett előzetes, kifejezett hozzájárulása esetén lehetséges.
                  A bíróság, az ügyész, a nyomozó hatóság, a szabálysértési
                  hatóság, a közigazgatási hatóság, a Nemzeti Adatvédelmi és
                  Információszabadság Hatóság, a Magyar Nemzeti Bank, illetőleg
                  jogszabály felhatalmazása alapján más szervek tájékoztatás
                  adása, adatok közlése, átadása, illetőleg iratok rendelkezésre
                  bocsátása végett megkereshetik az adatkezelőt. Adatkezelő a
                  hatóságok részére – amennyiben a hatóság a pontos célt és az
                  adatok körét megjelölte – személyes adatot csak annyit és
                  olyan mértékben ad ki, amely a megkeresés céljának
                  megvalósításához elengedhetetlenül szükséges. Az adatokat
                  elsődlegesen Adatkezelő és az Adatkezelővel esetlegesen
                  együttműködő belső munkatársak, valamint az adatkezeléssel,
                  adatfeldolgozással megbízott személyek jogosultak megismerni,
                  azokat nem teszik közzé, harmadik személyek részére nem adják
                  át és csak az Adatkezelési Nyilatkozatban meghatározott
                  célokra használják, illetve használhatják fel.Az adatkezelés
                  időtartama az adatok megadásától az Érintett hozzájárulásának
                  visszavonásáig, illetőleg addig tart, amíg az Érintett nem
                  tiltakozik adatai kezelése ellen, azonban az esetleges
                  hozzájárulás visszavonása nem érinti a visszavonás előtt a
                  hozzájárulás alapján végrehajtott adatkezelés jogszerűségét .
                  A személyes adatokhoz történő hozzáférés szigorúan korlátozott
                  a jogosulatlan megismerés, a személyes adatok jogosulatlan
                  megváltoztatása, illetve azok jogosulatlan felhasználásának
                  megelőzése érdekében. 5 Az adatok fizikai tárolási helyei Az
                  Érintettek személyes adatait az Adatkezelő információs
                  rendszereiben tárolja Tárhelyszolgáltató_ a weboldalon
                  böngészők adatait automatikusan naplózza Google Drive – az
                  érdeklődők, üzleti partnerek adatbázisának tárolási helye CRM
                  rendszer_üzleti partnerek adatainak tárolási helye 6
                  Adattovábbítás, adatfeldogozás, az adatokat megismerők köre
                  Adatkezelő vállalkozási tevékenysége keretében az alábbi
                  adatfeldolgozókat veszi igénybe: Tárhelyszolgáltató:
                  Rackforest Kft., E-mail: ugyfelszolgalat@rackforest.hu
                  Megismert adatok köre: greeninvestisland.hu domain-ek és
                  aldomain-eken található weboldalak tartalma, ezen domain-ekre
                  alapuló email címekre érkező emailek. Google Analytics: Google
                  Inc., Mountain View, California, USA Megismert adatok köre: a
                  greenislandinvest.hu weboldal látogatóinak – anonimizált,
                  személyhez nem köthető – IP címe. MailChimp: The Rocket
                  Science Group, LLC székhely: 675 Ponce de Leon Ave NE Suite
                  5000 Atlanta, GA 30308 USA; Facebook oldal: Facebook Inc.
                  Menlo Park, California, USA Adatkezelési tájékoztató:
                  https://www.facebook.com/about/privacy/update Megismert adatok
                  köre: felhasználónév, hozzászólás. Hírlevélküldő rendszer:
                  MiniCrem RendszerKönyvelő(iroda )(számlázási adatok körét)
                  Név: Menta Könyvelőiroda Kft. Székhely: 2151.Fót Illyés Gyula
                  utca 8. Adószám: 32093072-1-13 NAV (számlázási adatok
                  címzettje) 6.1 Adattovábbítás harmadik országba Adattovábbítás
                  az Amerikai Egyesült Államokba történik, mellyel megfelelőségi
                  határozat született július 12-én
                  (https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/eu-us-privacy-sh
                  ield_en). A megfelelőségi határozat a Mailchimp
                  https://mailchimp.com/legal/privacy/, a Google
                  (https://policies.google.com/privacy/frameworks) és a Facebook
                  (https://www.facebook.com/about/privacyshield) adatkezelők
                  esetében is érvényes. Érintett jogai és jogérvényesítési
                  lehetőségei Az érintett tájékoztatást kérhet személyes adatai
                  kezeléséről, valamint kérheti személyes adatainak
                  helyesbítését, illetve – a kötelező adatkezelések kivételével
                  – törlését, visszavonását, élhet adathordozási-, és
                  tiltakozási jogával az adat felvételénél jelzett módon,
                  illetve az adatkezelő fenti elérhetőségein. 7.1
                  Tájékoztatáshoz való jog Adatkezelő megfelelő intézkedéseket
                  hoz annak érdekében, hogy az érintettek részére a személyes
                  adatok kezelésére vonatkozó, a GDPR 13. és a 14. cikkben
                  említett valamennyi információt és a 15–22. és 34. cikk
                  szerinti minden egyes tájékoztatást tömör, átlátható, érthető
                  és könnyen hozzáférhető formában, világosan és közérthetően
                  megfogalmazva nyújtsa. 7.2 Az érintett hozzáféréshez való joga
                  Az érintett jogosult arra, hogy az adatkezelőtől visszajelzést
                  kapjon arra vonatkozóan, hogy személyes adatainak kezelése
                  folyamatban van-e, és ha ilyen adatkezelés folyamatban van,
                  jogosult arra, hogy a személyes adatokhoz és a következő
                  információkhoz hozzáférést kapjon: az adatkezelés céljai; az
                  érintett személyes adatok kategóriái;azon címzettek vagy
                  címzettek kategóriái, akikkel, illetve amelyekkel a személyes
                  adatokat közölték vagy közölni fogják, ideértve különösen a
                  harmadik országbeli címzetteket, illetve a nemzetközi
                  szervezeteket; a személyes adatok tárolásának tervezett
                  időtartama; a helyesbítés, törlés vagy adatkezelés
                  korlátozásának és a tiltakozás joga; a felügyeleti hatósághoz
                  címzett panasz benyújtásának joga; az adatforrásokra vonatkozó
                  információ; az automatizált döntéshozatal ténye, ideértve a
                  profilalkotást is, valamint az alkalmazott logikára és arra
                  vonatkozó érthető információk, hogy az ilyen adatkezelés
                  milyen jelentőséggel bír, és az érintettre nézve milyen
                  várható következményekkel jár. Az adatkezelő a kérelem
                  benyújtásától számított legfeljebb egy hónapon belül adja meg
                  a tájékoztatást. 7.3 Helyesbítés joga Az érintett kérheti
                  Adatkezelő által kezelt, rá vonatkozó pontatlan személyes
                  adatok helyesbítését és a hiányos adatok kiegészítését. 7.4
                  Törléshez való jog Az érintett az alábbi indokok
                  valamelyikének fennállása esetén jogosult arra, hogy kérésére
                  Adatkezelő indokolatlan késedelem nélkül törölje a rá
                  vonatkozó személyes adatokat: személyes adatokra már nincs
                  szükség abból a célból, amelyből azokat gyűjtötték vagy más
                  módon kezelték; az érintett visszavonja az adatkezelés alapját
                  képező hozzájárulását, és az adatkezelésnek nincs más
                  jogalapja; az érintett tiltakozik az adatkezelés ellen, és
                  nincs elsőbbséget élvező jogszerű ok az adatkezelésre; a
                  személyes adatokat jogellenesen kezelték; a személyes adatokat
                  az adatkezelőre alkalmazandó uniós vagy tagállami jogban
                  előírt jogi kötelezettség teljesítéséhez törölni kell; a
                  személyes adatok gyűjtésére információs társadalommal
                  összefüggő szolgáltatások kínálásával kapcsolatosan került
                  sor. Az adatok törlése nem kezdeményezhető, ha az adatkezelés
                  szükséges: a véleménynyilvánítás szabadságához és a
                  tájékozódáshoz való jog gyakorlása céljából; a személyes
                  adatok kezelését előíró, az adatkezelőre alkalmazandó uniós
                  vagy tagállami jog szerinti kötelezettség teljesítése, illetve
                  közérdekből vagy az adatkezelőre ruházott közhatalmi
                  jogosítvány gyakorlása keretében végzett feladat végrehajtása
                  céljából; a népegészség-ügy területét érintő, vagy
                  archiválási, tudományos és történelmi kutatási célból vagy
                  statisztikai célból, közérdek alapján; vagy jogi igények
                  előterjesztéséhez, érvényesítéséhez, illetve védelméhez. 7.5
                  Az adatkezelés korlátozásához való jog Az érintett kérésére
                  Adatkezelő korlátozza az adatkezelést, ha az alábbi feltételek
                  valamelyike teljesül: az érintett vitatja a személyes adatok
                  pontosságát, ez esetben a korlátozás arra az
                  időtartamravonatkozik, amely lehetővé teszi, a személyes
                  adatok pontosságának ellenőrzését; az adatkezelés jogellenes,
                  és az érintett ellenzi az adatok törlését, és ehelyett kéri
                  azok felhasználásának korlátozását; az adatkezelőnek már nincs
                  szüksége a személyes adatokra adatkezelés céljából, de az
                  érintett igényli azokat jogi igények előterjesztéséhez,
                  érvényesítéséhez vagy védelméhez; vagy az érintett tiltakozott
                  az adatkezelés ellen; ez esetben a korlátozás arra az
                  időtartamra vonatkozik, amíg megállapításra nem kerül, hogy az
                  adatkezelő jogos indokai elsőbbséget élveznek-e az érintett
                  jogos indokaival szemben. Ha az adatkezelés korlátozás alá
                  esik, a személyes adatokat a tárolás kivételével csak az
                  érintett hozzájárulásával, vagy jogi igények
                  előterjesztéséhez, érvényesítéséhez vagy védelméhez, vagy más
                  természetes vagy jogi személy jogainak védelme érdekében, vagy
                  az Unió, illetve valamely tagállam fontos közérdekéből lehet
                  kezelni. 7.6 Adathordozáshoz való jog Az érintett jogosult
                  arra, hogy a rá vonatkozó, általa az adatkezelő rendelkezésére
                  bocsátott személyes adatokat tagolt, széles körben használt,
                  géppel olvasható formátumban megkapja, és ezeket az adatokat
                  egy másik adatkezelőnek továbbítsa. 7.7 Tiltakozás joga Az
                  érintett jogosult arra, hogy a saját helyzetével kapcsolatos
                  okokból bármikor tiltakozzon személyes adatainak közérdekű
                  vagy az adatkezelőre ruházott közhatalmi jogosítvány
                  gyakorlásának keretében végzett feladat végrehajtásához
                  szükséges adatkezelés, vagy az adatkezelő vagy egy harmadik
                  fél jogos érdekeinek érvényesítéséhez szükséges kezelése
                  ellen, ideértve az említett rendelkezéseken alapuló
                  profilalkotást is. Tiltakozás esetén az adatkezelő a személyes
                  adatokat nem kezelheti tovább, kivéve, ha azt olyan
                  kényszerítő erejű jogos okok indokolják, amelyek elsőbbséget
                  élveznek az érintett érdekeivel, jogaival és szabadságaival
                  szemben, vagy amelyek jogi igények előterjesztéséhez,
                  érvényesítéséhez vagy védelméhez kapcsolódnak. 7.8
                  Automatizált döntéshozatal egyedi ügyekben, beleértve a
                  profilalkotást Az érintett jogosult arra, hogy ne terjedjen ki
                  rá az olyan, kizárólag automatizált adatkezelésen – ideértve a
                  profilalkotást is – alapuló döntés hatálya, amely rá nézve
                  joghatással járna vagy őt hasonlóképpen jelentős mértékben
                  érintené. 7.9 Visszavonás joga Az érintett jogosult arra, hogy
                  hozzájárulását bármikor visszavonja. 7.10 Jogorvoslati
                  jogAmennyiben észrevétele, problémája van a Green Island
                  Invest Zrt. adatkezelésével kapcsolatban, bizalommal jelezheti
                  Adatkezelő számára a II. pontban megjelölt elérhetőségek
                  bármelyikén. Az Érintett a jogainak megsértése esetén jogosult
                  bírósághoz fordulni, jogait a 2011. évi CXII. törvény
                  (Infotv.), valamint a Polgári törvénykönyv (2013. évi V.
                  törvény) alapján bíróság előtt érvényesítheti. A fentiek
                  mellett az Érintett panasszal élhet a Nemzeti Adatvédelmi és
                  Információszabadság Hatóságnál (székhely: 1125 Budapest,
                  Szilágyi Erzsébet fasor 22/c; postacím: 1530 Budapest, Pf.:
                  5., telefon: +36 (1) 391-1400, email: ugyfelszolgalat@naih.hu;
                  www.naih.hu) is. Javasoljuk, hogy amennyiben a felügyeleti
                  hatósághoz, bírósághoz fordulást tervezi, előzetesen az
                  Adatkezelőnél érdeklődjön, mivel a felmerült kérdései,
                  orvoslást igénylő kérései tekintetében a szükséges információk
                  az adatkezelőnél állnak rendelkezésre. Jelen tájékoztató a
                  módosításig, illetve a visszavonásig hatályos, melyről
                  Adatkezelő a módosítás, illetve visszavonás előtt legalább 15
                  nappal a honlapon értesíti Partnereit.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="solar__footer">
          <div className="solar__footer__container">
            <h2>Green Island Invest Zrt.</h2>
            <div style={{ fontSize: "0.8rem" }}>
              <p>2074 Perbál Ipari Park 17</p>
              <p>info@greenislandinvest.hu</p>
              <p>+36 1 300 9240</p>
              <p>+36 70 / 315-7070</p>
              <p>+36 70 / 309-7070</p>
            </div>
            <div className="solar__footer__container__btns">
              <button
                className="btn-primary"
                onClick={() => ASZFModel.chatButton.current.openChat()}>
                Visszahívást kérek
              </button>
              <br />
            </div>
          </div>
        </div>
      </div>
      <GoToTop />
      <FloatingChatButton ref={chatButton} />
    </>
  );
};

export default ASZFModel;
