import {
  ArticleRounded,
  AttachMoneyRounded,
  DocumentScannerRounded,
  RefreshRounded,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextareaAutosize,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import $ from "jquery";
import { default as React, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/authContext";
import config from "../../contexts/config";
import "../arajanlatozo/arajanlatozo.scss";
import employees from "../bejelentkezes/0ae127cd7614241e3a8527b0fa1a1900.json";
import DokumentumKitolto from "../dokumentumkitolto/dokumentumkitolto";
import "../solar/solar-model.scss";
import json from "./arak.json";
import json2 from "./bovites.json";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ArajanlatozoModel = () => {
  const [formData, setFormData] = useState({
    nettoAr: 0,
    afaAr: 0,
    teljesAr: 0,
    inverter: "",
    akkumulator: "",
    napelemdb: 0,
    napelem: "",
    klimadb: 0,
    inverterdb: 0,
    akkumulatordb: 0,
    kiegeszitokdb: 0,
    klima: "",
    kiegeszito: "",
    ugyfelnev: "",
    ugyfelcim: "",
    Id: "",
    ContactId: "",
    ertekesitonev:
      localStorage.getItem("felhasznalonev") === "Greenisland4611"
        ? ""
        : localStorage.getItem("nev"),
    ertekesitotelefon:
      localStorage.getItem("felhasznalonev") === "Greenisland4611"
        ? ""
        : localStorage.getItem("telefonszam"),
    ertekesitoAdmin: "",
  });
  const defaultFormData = {
    nettoAr: 0,
    afaAr: 0,
    teljesAr: 0,
    inverter: "",
    akkumulator: "",
    napelemdb: 0,
    napelem: "",
    klimadb: 0,
    inverterdb: 0,
    akkumulatordb: 0,
    kiegeszitokdb: 0,
    klima: "",
    kiegeszito: "",
    ugyfelnev: "",
    ugyfelcim: "",
    Id: "",
    ContactId: "",
    ertekesitonev:
      localStorage.getItem("felhasznalonev") === "Greenisland4611"
        ? ""
        : localStorage.getItem("nev"),
    ertekesitotelefon:
      localStorage.getItem("felhasznalonev") === "Greenisland4611"
        ? ""
        : localStorage.getItem("telefonszam"),
    ertekesitoAdmin: "",
  };
  const [frissites, setFrissites] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [isError, setError] = React.useState(false);
  const [lastChangedByAdmin, setLastChangedByAdmin] = React.useState(false);
  const [lastValueByAdmin, setLastValueByAdmin] = React.useState(0);
  const [currState, setCurrState] = React.useState("arajanlat");
  const [blogcim, setBlogcim] = React.useState("");
  const [blogtartalma, setBlogtartalma] = React.useState("");
  const [blogs, setBlogs] = React.useState([]);
  const [currentEdit, setCurrentEdit] = React.useState(null);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [selectedJson, setSelectedJson] = React.useState("json");
  const [kitoltotteId, setKitoltotteId] = React.useState(false);
  const [kitoltotteNev, setKitoltotteNev] = React.useState(false);

  const [prices, setPrices] = React.useState(json);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "Id") {
      setKitoltotteId(value !== "");
    } else if (name === "ugyfelnev") {
      setKitoltotteNev(value !== "");
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleJsonChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedJson(selectedValue);
    setPrices(selectedValue === "json" ? json : json2);
  };

  useEffect(() => {
    const tokenExpiration = localStorage.getItem("tokenExpiration");
    if (
      !currentUser ||
      (tokenExpiration && new Date().getTime() > tokenExpiration)
    ) {
      navigate("/bejelentkezes");
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (!currentUser) {
      navigate("/bejelentkezes");
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    fetch(`${config.API_URL_ONRENDER}/blog`)
      .then((res) => res.json())
      .then((data) => {
        setBlogs(data.reverse());
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  let adminElements;
  const [munkadij, setMunkadij] = React.useState(0);
  const [tartoszerkezet, setTartoszerkezet] = React.useState(0);
  const [egyeb, setEgyeb] = React.useState(0);

  const resetAndCalculate = () => {
    setMunkadij(0);
    setTartoszerkezet(0);
    setEgyeb(0);
    let munkadijszamolas = 0;
    let tartoszerkezetszamolas = 0;
    let egyebszamolas = 0;

    for (const key in prices) {
      if (key === "telepites") {
        for (const [, d] of Object.entries(prices[key])) {
          if (d["nev"] === "tartószerkezet") {
            tartoszerkezetszamolas += d["ar"];
            setTartoszerkezet(tartoszerkezetszamolas);
          } else if (d["nev"] === "munkadíj") {
            munkadijszamolas += d["ar"];
            setMunkadij(munkadijszamolas);
          } else {
            egyebszamolas += d["ar"];
            setEgyeb(egyebszamolas);
          }
        }
      }
    }
  };

  useEffect(() => {
    resetAndCalculate();
  }, [selectedJson]);

  let teljes = 0;
  let afa = 0;
  let teljesafaval = 0;

  let isAdmin = false;

  isAdmin = localStorage.getItem("felhasznalonev") === "Greenisland4611";
  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#000",
      },
    },
  });

  let loadingButton;

  if (isLoading) {
    loadingButton = (
      <div className="load-wrapp">
        <div className="load-2">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
    );
  } else {
    if (isError) {
      loadingButton = <span>Hiba történt!</span>;
    } else if (isSuccess) {
      loadingButton = <span>Sikeres árajánlat!</span>;
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    } else {
      loadingButton = <span>Árajánlat készítése</span>;
    }
  }

  const handleButtonClick = (e) => {
    setLoading(true);
    setSuccess(false);
    setError(false);

    const params = new URLSearchParams({
      CategoryId: 55,
    });
    if (kitoltotteNev) {
      params.append("Name", formData.ugyfelnev);
    } else if (kitoltotteId) {
      params.append("Id", formData.Id);
    }

    fetch(`${config.API_URL_ONRENDER}/minicrm/fullUser?${params.toString()}`, {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${localStorage.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSuccess(true);
        setLoading(false);

        // Check if there are multiple keys in the returned object
        if (Object.keys(data).length >= 1) {
          const azertekesito = Object.entries(employees).find(
            ([key, value]) => {
              return value.name === data.UserId;
            }
          );

          setFormData((previous) => ({
            ...previous,
            ugyfelcim:
              data.LakcimIranyitoszam +
              ", " +
              data.Lakcim +
              " " +
              data.LakcimUtcahazszam,
            ugyfelnev: data.Name,
            ContactId: data.ContactId,
            Id: data.Id,
            ertekesitoAdmin:
              azertekesito && azertekesito.length > 1
                ? JSON.stringify({
                    name: azertekesito[1].name,
                    telefonszam: azertekesito[1].telefonszam,
                  })
                : "",
          }));
          setLoading(false);
          setSuccess(true);
          setError(false);
        }
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
        console.error("Error:", error);
      });
  };

  const handleBlur = (e) => {
    setLastChangedByAdmin(true);
    setLastValueByAdmin(parseInt(e.target.textContent.replace(".", "")));

    getArak(parseInt(e.target.textContent.replace(".", "")));
  };

  const handleBlogFrissites = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    console.log(data.get("title"));
    const requestOptions = {
      method: currentEdit.id ? "PUT" : "POST",
      body: data,
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${localStorage.token}`, // Include the Bearer token
      },
    };

    let url =
      `${config.API_URL_ONRENDER}/blog` +
      (currentEdit.id ? `/${currentEdit.id}` : "");
    fetch(url, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());
        setCurrentEdit(null);
        loadData();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        alert("hiba", error);
        console.error("There was an error!", error);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const napelemdb = formData.napelemdb;
    const klimadb = formData.klimadb;
    const inverterdb = formData.inverterdb;
    const akkumulatordb = formData.akkumulatordb;
    const kiegeszitokdb = formData.kiegeszitokdb;
    if (frissites) {
      getArak(-1);
      setLastChangedByAdmin(false);
    } else {
      setLoading(true);
      let elemek = [];
      if (
        parseInt(napelemdb) !== 0 &&
        formData.napelem &&
        JSON.parse(formData.napelem)["nev"] !== 0 &&
        JSON.parse(formData.napelem)["nev"] !== ""
      ) {
        elemek.push({
          name: JSON.parse(formData.napelem)["nev"],
          quantity: napelemdb + " db",
        });
        elemek.push({
          name: "Tartószerkezet napelempanelek telepítéshez szereléssel",
          quantity: napelemdb + " db",
        });
      }
      if (
        parseInt(inverterdb) !== 0 &&
        formData.inverter &&
        JSON.parse(formData.inverter)["nev"] !== 0 &&
        JSON.parse(formData.inverter)["nev"] !== ""
      ) {
        elemek.push({
          name: JSON.parse(formData.inverter)["nev"],
          quantity: inverterdb + " db",
        });
      }

      if (
        parseInt(akkumulatordb) !== 0 &&
        formData.akkumulator &&
        JSON.parse(formData.akkumulator)["nev"] !== 0 &&
        JSON.parse(formData.akkumulator)["nev"] !== ""
      ) {
        elemek.push({
          name: JSON.parse(formData.akkumulator)["nev"],
          quantity: akkumulatordb + " db",
        });
      }

      if (
        parseInt(klimadb) !== 0 &&
        formData.klima &&
        JSON.parse(formData.klima)["nev"] !== 0 &&
        JSON.parse(formData.klima)["nev"] !== ""
      ) {
        elemek.push({
          name: JSON.parse(formData.klima)["nev"],
          quantity: klimadb + " db",
        });
      }

      function arformazo(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }

      if (
        parseInt(kiegeszitokdb) !== 0 &&
        formData.kiegeszito &&
        JSON.parse(formData.kiegeszito)["nev"] !== 0 &&
        JSON.parse(formData.kiegeszito)["nev"] !== ""
      ) {
        elemek.push({
          name: JSON.parse(formData.kiegeszito)["nev"],
          quantity: kiegeszitokdb + " db",
        });
      }

      let arak;

      if (lastChangedByAdmin) {
        arak = getArak(lastValueByAdmin);
      } else {
        arak = getArak(-1);
      }

      let today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();
      today = yyyy + "." + mm + "." + dd;

      let kw =
        formData.napelem.split(" ")[formData.napelem.split(" ").length - 1];

      elemek.push({
        name: "Szerelési anyagok, szolár kábelek és csatlakozók",
        quantity: "1 klt",
      });

      elemek.push({
        name: "AC-DC biztonsági szerelvények, túlfeszültségvédelem",
        quantity: "1 klt",
      });

      elemek.push({
        name: "Tervezés, engedélyezés, érintésvédelem",
        quantity: "1 klt",
      });

      elemek.push({
        name: "Kivitelezési díj",
        quantity: "1 klt",
      });

      const ertekesitoAdminJSON = isAdmin
        ? JSON.parse(formData.ertekesitoAdmin)
        : null;

      const jsonData = {
        template_id:
          selectedJson === "json"
            ? "Q75gTX20f1uildGFQqL8"
            : "KuIa2zjgg4Ae7pRnnoCX",
        data: {
          name: data.get("ugyfelnev"),
          address: data.get("ugyfelcim"),
          kwp:
            (parseInt(kw.substring(0, kw.length - 1)) * parseInt(napelemdb)) /
            1000,
          priceNoTax: arformazo(arak["netto"]),
          tax: arformazo(arak["afa"]),
          priceTax: arformazo(arak["brutto"]),
          items: elemek,
          ertekesitoNeve: ertekesitoAdminJSON
            ? ertekesitoAdminJSON.name
            : sessionStorage.getItem("nev"),
          ertekesitoTelefonszam: ertekesitoAdminJSON
            ? ertekesitoAdminJSON.telefonszam
            : sessionStorage.getItem("telefonszam"),
          date: today,
        },
        file: "",
        sheet: "",
        name: "",
        output_name:
          data.get("ugyfelnev") !== "" && data.get("ugyfelnev") != null
            ? data.get("ugyfelnev")
            : "arajanlat",
        output_format: ".pdf",
        output_quality: 100,
        single_file: true,
        page_break: true,
        merge_with: "",
      };

      $.ajax({
        url: "https://api.docugenerate.com/v1/document",
        type: "post",
        data: JSON.stringify(jsonData),
        headers: {
          accept: "application/json",
          Authorization: "945c5c49b7f73ff286550a920c167eba",
          "Content-Type": "application/json",
        },
        dataType: "json",
        success: function (data) {
          if (downloadDocument) {
            window.location.href = data.document_uri;
          }
          const documentUri = data.document_uri;
          const params = new URLSearchParams({
            id: formData.Id,
          });

          const requestBody = {
            File2433: documentUri,
            Float2427: arak["brutto"],
            Inverter:
              parseInt(inverterdb) !== 0 &&
              formData.inverter &&
              JSON.parse(formData.inverter)["nev"] !== 0 &&
              JSON.parse(formData.inverter)["nev"] !== ""
                ? JSON.parse(formData.inverter)["nev"]
                : "",
            InverterDarabszam: parseInt(inverterdb),
            Napelempanel:
              parseInt(napelemdb) !== 0 &&
              formData.napelem &&
              JSON.parse(formData.napelem)["nev"] !== 0 &&
              JSON.parse(formData.napelem)["nev"] !== ""
                ? JSON.parse(formData.napelem)["nev"]
                : "",
            PanelDarabszam: parseInt(napelemdb),
            EnergiataroloDarabszam: parseInt(akkumulatordb),
            Energiatarolo3:
              parseInt(akkumulatordb) !== 0 &&
              formData.akkumulator &&
              JSON.parse(formData.akkumulator)["nev"] !== 0 &&
              JSON.parse(formData.akkumulator)["nev"] !== ""
                ? JSON.parse(formData.akkumulator)["nev"]
                : "",
            NevlegesTeljesitmeny:
              (parseInt(kw.substring(0, kw.length - 1)) * parseInt(napelemdb)) /
              1000,
            StatusId: 3444,
          };

          fetch(
            `${
              config.API_URL_ONRENDER
            }/minicrm/uploadFile?${params.toString()}`,
            {
              method: "PUT",
              headers: {
                accept: "application/json",
                Authorization: `Bearer ${localStorage.token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestBody),
            }
          )
            .then((response) => {
              setLoading(false);
              if (!response.ok) {
                setError(true);
                return response.json().then((error) => {
                  throw new Error(error.message || "Unknown error");
                });
              }
              setSuccess(true);

              return response.json();
            })
            .then(() => {})
            .catch((error) => {
              setError(true);
              setLoading(false);
              console.error("Error:", error);
            });
        },
        error: function (data) {
          setError(true);
        },
      });
    }
  };

  const loadData = () => {
    fetch(`${config.API_URL_ONRENDER}/blog`)
      .then((res) => res.json())
      .then((data) => {
        setBlogs(data.reverse());
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const deleteBlog = (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.token}`, // Include the Bearer token
      },
    };
    fetch(`${config.API_URL_ONRENDER}/blog/` + id, requestOptions).then(() =>
      loadData()
    );
  };

  const [downloadDocument, setDownloadDocument] = useState(false);

  const handleCheckboxChange = (event) => {
    setDownloadDocument(event.target.checked);
  };

  const getArak = (teljesar) => {
    function arformazo(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    if (teljesar === -1) {
      const napelemekar =
        formData.napelem &&
        JSON.parse(formData.napelem)["ar"] !== 0 &&
        JSON.parse(formData.napelem)["ar"] !== ""
          ? JSON.parse(formData.napelem)["ar"] * formData.napelemdb
          : 0;
      const inverterekar =
        formData.inverter &&
        JSON.parse(formData.inverter)["ar"] !== 0 &&
        JSON.parse(formData.inverter)["ar"] !== ""
          ? JSON.parse(formData.inverter)["ar"] * formData.inverterdb
          : 0;
      const akkumulatorar =
        formData.akkumulator &&
        JSON.parse(formData.akkumulator)["ar"] !== 0 &&
        JSON.parse(formData.akkumulator)["ar"] !== ""
          ? JSON.parse(formData.akkumulator)["ar"] * formData.akkumulatordb
          : 0;
      const klimaar =
        formData.klima &&
        JSON.parse(formData.klima)["ar"] !== 0 &&
        JSON.parse(formData.klima)["ar"] !== ""
          ? JSON.parse(formData.klima)["ar"] * formData.klimadb
          : 0;

      const inverter_kiegeszitokar =
        formData.kiegeszito &&
        JSON.parse(formData.kiegeszito)["ar"] !== 0 &&
        JSON.parse(formData.kiegeszito)["ar"] !== ""
          ? JSON.parse(formData.kiegeszito)["ar"] * formData.kiegeszitokdb
          : 0;
      teljes =
        napelemekar +
        inverterekar +
        akkumulatorar +
        inverter_kiegeszitokar +
        klimaar +
        egyeb;
      teljes += formData.napelemdb * munkadij;
      teljes += formData.napelemdb * tartoszerkezet;
      teljesafaval = Math.round(teljes) * 1.27;
      afa = Math.round(teljesafaval) - Math.round(teljes);
    } else {
      teljesafaval = teljesar;
      teljes = Math.round(teljesafaval / 1.27);
      afa = teljesafaval - teljes;
    }

    setFormData((previous) => ({
      ...previous,
      nettoAr: Math.round(teljes),
      afaAr: Math.round(afa),
      teljesAr: Math.round(teljesafaval),
    }));
    setFrissites(false);

    return {
      netto: Math.round(teljes),
      afa: Math.round(afa),
      brutto: Math.round(teljesafaval),
    };
  };
  if (isAdmin) {
    adminElements = (
      <>
        {" "}
        <Grid item xs={6} sx={{ mx: "auto" }}>
          <FormControl variant="standard" sx={{ minWidth: "100%" }}>
            <InputLabel id="demo-simple-select-label">Értékesítő</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formData.ertekesitoAdmin}
              name="ertekesitoAdmin"
              label="Értékesítő"
              onChange={handleChange}>
              {Object.entries(employees)
                .filter(([key, value]) => value.name) // Filter out employees without a name property
                .map(([key, value]) => (
                  <MenuItem
                    value={JSON.stringify({
                      name: value["name"],
                      telefonszam: value["telefonszam"],
                    })}
                    key={value["name"] + value["telefonszam"]}>
                    {value["name"]}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </>
    );
  } else {
    adminElements = <></>;
  }
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Avatar sx={{ m: 1, bgcolor: "black" }}>
            <AttachMoneyRounded />
          </Avatar>
          <Typography component="h1" variant="h5">
            {currState === "arajanlat"
              ? "Árajánlat készítése"
              : currState === "blog"
              ? "Blog frissítése"
              : "Dokumentum kitöltés"}
          </Typography>
          <Grid container spacing={2} sx={{ mt: 3, mb: 4 }}>
            {isAdmin ? (
              <Grid item xs={12} sm={6}>
                <Button
                  className="btn-secondary-inverse"
                  type="submit"
                  id="frissit"
                  fullWidth
                  variant="contained"
                  onClick={(e) => {
                    if (currentEdit) {
                      setCurrentEdit(null);
                    } else {
                      if (currState === "blog") {
                        setCurrState("arajanlat");
                      } else {
                        setCurrState("blog");
                      }
                    }
                  }}>
                  <ArticleRounded sx={{ mx: 2 }} />
                  {!currentEdit
                    ? currState === "blog"
                      ? "Árajánlat készítése"
                      : "Blog frissítése"
                    : "Vissza a blogokhoz"}
                </Button>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12} sm={6}>
              <Button
                className="btn-secondary-inverse"
                type="submit"
                id="frissit"
                fullWidth
                variant="contained"
                onClick={(e) => {
                  if (currState === "dokumentum") {
                    setCurrState("arajanlat");
                  } else {
                    setCurrState("dokumentum");
                  }
                }}>
                <DocumentScannerRounded sx={{ mx: 2 }} />
                {currState === "dokumentum"
                  ? "Árajánlat készítése"
                  : "Dokumentum kitöltés"}
              </Button>
            </Grid>
          </Grid>
          {isAdmin && currState === "blog" && !currentEdit ? (
            <Button
              className="btn-secondary-inverse"
              type="submit"
              id="frissit"
              fullWidth
              variant="contained"
              onClick={(e) => {
                setCurrentEdit({});
              }}
              sx={{ mb: 2 }}>
              <AddIcon />
              Új blog hozzáadása
            </Button>
          ) : (
            <></>
          )}

          {currState === "blog" ? (
            <Box
              component="form"
              onSubmit={handleBlogFrissites}
              noValidate={true}
              sx={{ mt: 3 }}
              style={{ width: "100%" }}>
              {currentEdit ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      name="title"
                      required
                      fullWidth
                      id="title"
                      label="Blog címe"
                      value={currentEdit.title}
                      autoFocus
                      onChange={(e) => setBlogcim(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextareaAutosize
                      name="content"
                      required
                      style={{ width: "100%" }}
                      id="content"
                      placeholder="Blog tartalma"
                      value={currentEdit.content}
                      minRows={5}
                      autoFocus
                      onChange={(e) => setBlogtartalma(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}>
                      Fájl feltöltése
                      <VisuallyHiddenInput
                        type="file"
                        id="file"
                        name="file"
                        accept="image/jpg, image/png, image/jpeg, image/gif, video/mp4, video/mov, video/avi, video/wmv"
                      />
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}

              {!currentEdit ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <table>
                      <tbody>
                        {blogs.map((blog) => (
                          <tr key={blog.id}>
                            <td>{blog.title}</td>
                            <td style={{ textAlign: "right" }}>
                              <button
                                className="btn-secondary"
                                type="button"
                                onClick={() => setCurrentEdit(blog)}
                                style={{
                                  border: "1px solid black",
                                  padding: "12px",
                                  marginRight: "12px",
                                }}>
                                Szerkesztés
                              </button>{" "}
                              <button
                                className="btn-secondary-inverse"
                                style={{ padding: "12px" }}
                                type="button"
                                onClick={() => deleteBlog(blog.id)}>
                                Törlés
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}

              {currentEdit ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Button
                      className="btn-secondary-inverse"
                      style={{ width: "100%" }}
                      type="submit"
                      id="frissit"
                      variant="contained"
                      onClick={(e) => setFrissites(true)}
                      sx={{ mt: 3, mb: 2 }}>
                      <RefreshRounded />
                      Blog frissítése
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
            </Box>
          ) : currState === "arajanlat" ? (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate={true}
              sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} sx={{ mx: "auto", mb: "2rem" }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Válassza ki az árajánlat típusát
                    </InputLabel>
                    <Select
                      name="selectedJson"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedJson}
                      label="Válassza ki az árajánlat típusát"
                      onChange={handleJsonChange}>
                      <MenuItem value="json">Komplett rendszer</MenuItem>
                      <MenuItem value="json2">Napelemes bővítés</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} sx={{ mx: "auto", mb: "2rem" }}>
                  <TextField
                    autoComplete="Id"
                    name="Id"
                    required
                    disabled={kitoltotteNev}
                    fullWidth
                    id="Id"
                    label="Ügyfél ID"
                    autoFocus
                    value={formData.Id}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="customer-name"
                    name="ugyfelnev"
                    required
                    disabled={kitoltotteId}
                    fullWidth
                    id="ugyfelnev"
                    label="Ügyfél név"
                    autoFocus
                    value={formData.ugyfelnev}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="ugyfelcim"
                    label="Ügyfél cím"
                    value={formData.ugyfelcim}
                    name="ugyfelcim"
                    autoComplete="customer-address"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    flexWrap: "wrap",
                    rowGap: 2,
                    columnGap: 5,
                  }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleButtonClick}>
                    {isLoading ? (
                      <CircularProgress size={24} style={{ color: "white" }} />
                    ) : (
                      " Felhasználó keresése"
                    )}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setKitoltotteId(false);
                      setKitoltotteNev(false);
                      setFormData(defaultFormData);
                    }}>
                    {isLoading ? (
                      <CircularProgress size={24} style={{ color: "white" }} />
                    ) : (
                      "Törlés"
                    )}
                  </Button>
                </Grid>
                {adminElements}
                <Grid item xs={10}>
                  <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Napelem
                    </InputLabel>
                    <Select
                      name="napelem"
                      value={formData.napelem}
                      label="Napelem"
                      onChange={handleChange}>
                      {Object.entries(prices["napelemek"]).map(
                        ([key, value]) => {
                          return (
                            <MenuItem
                              value={JSON.stringify({
                                ar: value["ar"],
                                nev: value["nev"],
                              })}
                              key={value["nev"] + value["ar"]}>
                              {value["nev"]}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    required
                    fullWidth
                    id="napelemdb"
                    label="Darab"
                    name="napelemdb"
                    autoComplete="napelemdb"
                    value={formData.napelemdb}
                    onChange={handleChange}
                  />
                </Grid>
                {prices["inverterek"] &&
                Object.entries(prices["inverterek"]) ? (
                  <>
                    <Grid item xs={10}>
                      <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Inverter
                        </InputLabel>
                        <Select
                          name="inverter"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formData.inverter}
                          label="Inverter"
                          onChange={handleChange}>
                          {Object.entries(prices["inverterek"]).map(
                            ([key, value]) => {
                              return (
                                <MenuItem
                                  value={JSON.stringify({
                                    ar: value["ar"],
                                    nev: value["nev"],
                                  })}
                                  key={value["nev"] + value["ar"]}>
                                  {value["nev"]}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        required
                        fullWidth
                        id="inverterdb"
                        label="Darab"
                        name="inverterdb"
                        value={formData.inverterdb}
                        autoComplete="inverterdb"
                        onChange={handleChange}
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                {prices["akkumulatorok"] &&
                Object.entries(prices["akkumulatorok"]) ? (
                  <>
                    <Grid item xs={10}>
                      <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Akkumulátor
                        </InputLabel>
                        <Select
                          name="akkumulator"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formData.akkumulator}
                          label="Napelem"
                          onChange={handleChange}>
                          {Object.entries(json["akkumulatorok"]).map(
                            ([key, value]) => {
                              return (
                                <MenuItem
                                  value={JSON.stringify({
                                    ar: value["ar"],
                                    nev: value["nev"],
                                  })}
                                  key={value["nev"] + value["ar"]}>
                                  {value["nev"]}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        required
                        fullWidth
                        id="akkumulatordb"
                        label="Darab"
                        value={formData.akkumulatordb}
                        name="akkumulatordb"
                        autoComplete="akkumulatordb"
                        onChange={handleChange}
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                {prices["inverter_kiegeszitok"] &&
                Object.entries(prices["inverter_kiegeszitok"]) ? (
                  <>
                    <Grid item xs={10}>
                      <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Inverter kiegészítő
                        </InputLabel>
                        <Select
                          name="kiegeszito"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formData.kiegeszito}
                          label="Inverter kiegészítő"
                          onChange={handleChange}>
                          {Object.entries(json["inverter_kiegeszitok"]).map(
                            ([key, value]) => {
                              return (
                                <MenuItem
                                  value={JSON.stringify({
                                    ar: value["ar"],
                                    nev: value["nev"],
                                  })}
                                  key={value["nev"] + value["ar"]}>
                                  {value["nev"]}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        required
                        fullWidth
                        id="kiegeszitokdb"
                        label="Darab"
                        name="kiegeszitokdb"
                        value={formData.kiegeszitokdb}
                        autoComplete="kiegeszitokdb"
                        onChange={handleChange}
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                {prices["klimak"] && Object.entries(prices["klimak"]) ? (
                  <>
                    <Grid item xs={10}>
                      <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Klíma
                        </InputLabel>
                        <Select
                          name="klima"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formData.klima}
                          label="Klíma"
                          onChange={handleChange}>
                          {Object.entries(json["klimak"]).map(
                            ([key, value]) => {
                              return (
                                <MenuItem
                                  value={JSON.stringify({
                                    ar: value["ar"],
                                    nev: value["nev"],
                                  })}
                                  key={value["nev"] + value["ar"]}>
                                  {value["nev"]}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        required
                        fullWidth
                        id="klimadb"
                        label="Darab"
                        name="klimadb"
                        value={formData.klimadb}
                        autoComplete="klimadb"
                        onChange={handleChange}
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
              <TableContainer component={Paper} style={{ marginTop: "48px" }}>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow
                      key={"nettoar"}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}>
                      <TableCell component="th" scope="row">
                        Új napelemes rendszer megvalósítása kW méretben (nettó
                        Ft)
                      </TableCell>
                      <TableCell align="right">{formData.nettoAr} Ft</TableCell>
                    </TableRow>
                    <TableRow
                      key="afa"
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}>
                      <TableCell component="th" scope="row">
                        ÁFA (27%)
                      </TableCell>
                      <TableCell align="right">{formData.afaAr} Ft</TableCell>
                    </TableRow>
                    <TableRow
                      key="teljesar"
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}>
                      <TableCell component="th" scope="row">
                        Végösszeg (bruttó Ft)
                      </TableCell>
                      <TableCell align="right">
                        <strong
                          contentEditable={isAdmin}
                          suppressContentEditableWarning={true}
                          id="teljesAr"
                          onBlur={handleBlur}>
                          {formData.teljesAr}
                        </strong>
                        <span> Ft</span>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container spacing={2} sx={{ mt: 3 }}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                  }}>
                  <Button
                    className="btn-secondary-inverse"
                    type="submit"
                    id="frissit"
                    fullWidth
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setLastChangedByAdmin(false);
                      setFrissites(true);
                      getArak(-1);
                    }}
                    sx={{ mb: 2 }}>
                    <RefreshRounded />
                    Frissítés
                  </Button>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <FormControlLabel
                    style={{ float: "left" }}
                    control={
                      <Checkbox
                        checked={downloadDocument}
                        onChange={handleCheckboxChange}
                        color="primary"
                      />
                    }
                    label="Dokumentumok letöltése"
                  />
                  <Button
                    className="btn-secondary-inverse"
                    type="submit"
                    id="bekuld"
                    fullWidth
                    variant="contained"
                    sx={{ mb: 2 }}>
                    {loadingButton}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <DokumentumKitolto />
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ArajanlatozoModel;
